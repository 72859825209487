import { useNavigate } from "react-router-dom";
import { appDownloadUrl, pageRoutes } from "../important/commanFunction";
import { Link } from "react-router-dom";

const HomePage2 = () => {
    const navigate = useNavigate();

    const handleNavigate = (path, newPage) => {
        if (newPage) {
            window.open(path, "_blank"); 
        } else {
            navigate(path);
        }
    };

    const heightSc = "450px"
    return (
        <>
            <div id="heroCarousel" className="carousel slide" data-bs-ride="carousel" style={{ height: heightSc, marginBottom: "30px" }}>
                <div className="carousel-indicators">
                    <button type="button" data-bs-target="#heroCarousel" data-bs-slide-to="0" className="active" aria-current="true"
                        aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#heroCarousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#heroCarousel" data-bs-slide-to="2" aria-label="Slide 3"></button>
                </div>
                <div className="carousel-inner" style={{ height: heightSc }} >
                    <div className="carousel-item active" data-bs-interval="10000" style={{ height: heightSc }}>
                        <div className="home-page-hero" style={{ height: heightSc }}>
                            <img src={'/images/images/city-skyline.webp'} style={{ height: heightSc }} />
                            <div className="hero-content">
                                <p >Best from your <br />city and around!</p>
                                <button className="black_button" onClick={() => { handleNavigate(appDownloadUrl, true) }}>
                                    Get the App
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item" data-bs-interval="20000" style={{ height: heightSc }}>
                        <div className="home-page-hero" style={{ height: heightSc }}>
                            <img src={'/images/images/hero3.png'} style={{ height: heightSc }} />
                            <div className="hero-content">
                                <p >Best from your <br />city and around!</p>
                                <button className="black_button" onClick={() => { handleNavigate(appDownloadUrl, true) }}>
                                    Get the App
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="carousel-item" style={{ height: heightSc }}>
                        <div className="home-page-hero" style={{ height: heightSc }}>
                            <img src={'/images/images/hero4.png'} style={{ height: heightSc }} />
                            <div className="hero-content">
                                <p >Best from your <br />city and around!</p>
                                <button className="black_button" onClick={() => { handleNavigate(appDownloadUrl, true) }}>
                                    Get the App
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row" >
            </div>
            <section className="unlock-city">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center">
                            <h2>Unlock the Best of <br />Your City</h2>
                        </div>
                    </div>
                    <div className="row gap-3 gap-md-0">
                        
                        <div className="col-12 col-md-6 col-lg-4 d-flex mb-3">
                            <div className="card align-items-start">
                                <img src="/images/images/calendar-icon.svg" alt="Calendar Icon" />
                                <div className="title-desc mt-4">
                                    <h4 className="mb-4" id="unlock_best">Never miss a <br />cool event in your city</h4>
                                    <p>Launch your next online or offline <br />event with listy and reach the <br />right audience.</p>
                                </div>
                                <Link className="btn btn-purple text-white mt-auto" to={pageRoutes.addEvent}>
                                    Launch Your Event</Link>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 d-flex mb-3" >
                            <div className="card align-items-start">
                                <img src="/images/images/growth-icon.svg" alt="Growth Icon" />
                                <div className="title-desc mt-4">
                                    <h4 className="mb-4"  >Grow your business <br />with listy city</h4>
                                    <p >List your business with us and reach <br />people searching for services like yours!<br />Its quick, easy
                                        and effective.</p>
                                </div>
                                <Link className="btn btn-purple text-white mt-auto" to={pageRoutes.registerBusiness}>
                                    List Your Business</Link>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 d-flex mb-3">
                            <div className="card align-items-start">
                                <img src="/images/images/vendor-icon.svg" alt="Vendor Icon" />
                                <div className="title-desc mt-4">
                                    <h4 className="mb-4">Find local services and events</h4>
                                    <p>Whether you’re searching for a <br />fitness trainer, a trusted tutor, or such  <br />local services and events,
                                        listy city has you covered.</p>
                                </div>
                                <a href={appDownloadUrl} className="btn btn-purple text-white mt-auto" target="_blank">Download App</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="row">
                <div className="col-md-6 home-contect-div-download">
                    <h1>Download & Start Exploring Today</h1>
                    <button className="yellow_button" onClick={() => { handleNavigate(appDownloadUrl, true) }} >Download Now</button>
                </div>
                <div className="col-md-6 home-contect-div" style={{ marginBottom: "30px" }}>
                    <div className="box_shadow2" style={{ borderRadius: "10px" }}>
                        <p>Don’t miss out on all the local events and
                            services around you. Download the app and
                            stay connected with your city!</p>
                    </div>
                    <div className="box_shadow2" style={{ borderRadius: "10px" }}>
                        <p>Whether you’re searching for a
                            fitness trainer, a trusted tutor, or such
                            local services and events, listy city has you covered.</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HomePage2;