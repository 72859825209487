import { Outlet } from "react-router-dom";
import { pageRoutes } from "../important/commanFunction";
import Footer from "./Footer";
import { Link } from "react-router-dom";

const HomePageLayout = () => {
  return (
    <>
      <header className="header home px-2 px-md-5">
        <nav className="navbar navbar-expand-md navbar-dark bg-transparent" aria-label="Fourth navbar example" style={{padding:"0px"}}>
          <div className="container-fluid">
            <Link className="navbar-brand" to={pageRoutes.home} style={{padding:"0px"}}>
              <img src="/images/images/logo.png" alt="Brand Logo" style={{height:"85px"}}/>
            </Link>
            <ul className="navbar-nav nowrap align-items-center gap-4 ms-auto mb-2 mb-md-0 d-none d-md-flex">
              <li className="nav-item">
                <Link className="nav-link btn btn-purple" to={pageRoutes.addEvent}>
                  Launch Event</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link btn btn-purple" to={pageRoutes.registerBusiness}>List Business</Link>
              </li>
            </ul>
            <a href="#unlock_best" className="btn btn-purple text-white d-block d-md-none">Get Started</a>
          </div>
        </nav>
      </header>
      <Outlet />
      <Footer />
    </>
  );
};

export default HomePageLayout;
