import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";
import "react-bootstrap-typeahead/css/Typeahead.css";

import "./css/new-style.css";
import "./css/app-style.css";
import "./css/upcoming-event.css";
import "./css/style.css";
import "./css/edit-event.css";

import MainLayout from "./component/MainLayout";
import HomeLayout from "./component/HomeLayout";
import HomePageLayout from "./component/HomePageLayout";
import { pageRoutes } from "./important/commanFunction";
import HomePage2 from "./component/HomePage2";
import AboutPage from "./component/AboutPage";
import PrivacyPolicy from "./component/PrivacyPolicy";
import TermsPage from "./component/TermsPage";
import RegisterBusinessPage from "./component/RegisterBusinessPage";
import RegisterBusinessAdminPage from "./component/RegisterBusinessAdminPage";
import AddEventPage from "./component/AddEventPage";
import AddEventAdminPage from "./component/AddEventAdminPage";
import EnrollNowPage from "./component/EnrollNowPage";
import DetailsPage from "./component/DetailsPage";
import QrCodepage from "./component/QrCodePage";
import BusinessHelpPage from "./component/BusinessHelpPage";
import GernalHelpPage from "./component/GernalHelpPage";
import { ToastContainer } from "react-toastify";
import Discussion from "./component/Discussion";
import EventEditApp from "./component/EventEditApp";
import FaqPage from "./component/FaqPage";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePageLayout />}>
          <Route path={pageRoutes.home} element={<HomePage2 />} />
        </Route>
        <Route path={pageRoutes.eventEdit+"*"} element={<EventEditApp />} />
        <Route path={pageRoutes.discussion} element={<Discussion />} />
        <Route path="/" element={<HomeLayout />}>
          <Route path={pageRoutes.aboutPage} element={<AboutPage />} />
          <Route path={pageRoutes.faqPage} element={<FaqPage />} />
          <Route path={pageRoutes.detailsPage + '/:type/:slug'} element={<DetailsPage />} />
          <Route path={pageRoutes.qrCodePage} element={<QrCodepage />} />
          <Route path={pageRoutes.privacyPolicy} element={<PrivacyPolicy />} />
          <Route path={pageRoutes.termsPage} element={<TermsPage />} />
        </Route>
        <Route path="/" element={<MainLayout />}>
          <Route
            path={pageRoutes.registerBusiness}
            element={<RegisterBusinessPage />}
          />
          <Route
            path={pageRoutes.registerBusinessAdmin}
            element={<RegisterBusinessAdminPage />}
          />
          <Route
            path={pageRoutes.addEvent}
            element={<AddEventPage />}
          />
          <Route
            path={pageRoutes.addAdminEvent}
            element={<AddEventAdminPage />}
          />
          <Route
            path={pageRoutes.enrollNowPage + '/:type/:slug'}
            element={<EnrollNowPage />}
          />
        </Route>

        <Route path={pageRoutes.appPrivacyPolicy} element={<PrivacyPolicy />} />
        <Route path={pageRoutes.appTermsPage} element={<TermsPage />} />
        <Route path={pageRoutes.businessHelp} element={<BusinessHelpPage />} />
        <Route path={pageRoutes.generalHelp} element={<GernalHelpPage />} />
        <Route
          path={pageRoutes.webRegisterBusiness}
          element={<RegisterBusinessPage />}
        />
        <Route
          path={pageRoutes.webAddEvent}
          element={<AddEventPage />}
        />
        <Route path="*" element={<Navigate to={pageRoutes.home} />} />
      </Routes>
      <ToastContainer autoClose={3000} />
    </BrowserRouter>
  );
}

export default App;
