import { useEffect, useState } from "react";
import RegisterPageStep1 from "./AddEventStep1";
import RegisterPageStep2 from "./AddEventStep2";
import RegisterPageStep3 from "./AddEventStep3";
import {  toast } from "react-toastify";

const RegisterBusinessPage = () => {
  const [registerStep, setRegisterStep] = useState(1);
  const [formValue, setFormValue] = useState({});
  const [apiResponce, setApiResponce] = useState({})

  const inputChange = (name, value) => {
    setFormValue({
      ...formValue,
      [name]: value,
    });
  };

  useEffect(() => {
    if (registerStep === 3) {
      toast.success("Event launched successfully");
    }
  }, [registerStep])

  return (
    <>
      <section className="business-onboarding">
        <div className="container" style={{paddingLeft:"0px", paddingRight:"0px"}}>
          <div className="row">
            <div className="col-12 col-md-6 d-none d-md-block">
              <img src="/images/images/event-girl.png" className="w-100 mb-5" alt="Event Image" />
            </div>
            <div className="col-12 col-md-6">
              <h2 className="primary text-center mb-5">Launch your event for
                free in 3 easy steps.</h2>
              <ul className="stepper-form">
                <li>
                  <div className="stepper-item">
                    <span>1</span> Login
                  </div>
                  <span className={`progress ${registerStep === 1 && 'bg-warning'}`}></span>
                </li>
                <li>
                  <div className="stepper-item">
                    <span>2</span> Details
                  </div>
                  <span className={`progress ${registerStep === 2 && 'bg-warning'}`}></span>
                </li>
                <li>
                  <div className="stepper-item">
                    <span>3</span> Launch
                  </div>
                  <span className={`progress ${registerStep > 2 && 'bg-warning'}`}></span>
                </li>
              </ul>
              {registerStep === 1 && (
                <RegisterPageStep1
                  formValue={formValue}
                  inputChange={inputChange}
                  stepChange={(val) => {
                    setRegisterStep(val);
                  }}
                />
              )}
              {registerStep === 2 && (
                <RegisterPageStep2
                  formValue={formValue}
                  inputChange={inputChange}
                  apiDataSet = {(val)=>{
                    setApiResponce(val)
                  }}
                  stepChange={(val) => {
                    setRegisterStep(val);
                  }}
                />
              )}
              {(registerStep === 3 || registerStep === 4) && (
                <RegisterPageStep3
                apiResponce={apiResponce}
                  registerStep={registerStep}
                />
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RegisterBusinessPage;
