import axios from "axios";
import { useEffect, useState } from "react";
import { apiBaseUrl3, apiUrl, pageRoutes } from "../important/commanFunction";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";

const EventEditApp = () => {
    const [registerStep, setRegisterStep] = useState(0)
    useEffect(() => {
        const button = document.getElementById("button_slide_0");
        if (button) {
            button.click();
        }
    }, []);

    const [loading, setLoading] = useState(true);
    const [attendeeList, setAtendeeList] = useState(false);
    const [allowAttendeeMail, setAllowAttendeeMail] = useState(false);
    const [notificationList, setNotificationist] = useState(false);
    const [details, setDetails] = useState({
    }
    );
    const [list, setList] = useState([]
    );
    const [formData, setFormData] = useState({});
    const location = useLocation();
    const slug = location.pathname.replace(pageRoutes.eventEdit, '');

    const getDetails = () => {
        const headers = {
            Accept: "*/*",
            "Content-Type": "application/json",
        };

        axios
            .post(
                apiBaseUrl3 + apiUrl.getEventDashboard,
                {
                    "_inapprequesttoken": slug,
                    "secureRequestVO": {
                        "cardId": 0,
                        "cardType": "BUSINESS",
                        "forumType": "BUSINESS_FORUM",
                        "userId": 0
                    }
                },
                {
                    headers: headers,
                }
            )
            .then((response) => {
                if (response?.data?.responseType === "SUCCESS") {
                    setDetails(response?.data);
                    setFormData(response?.data?.userEventVO || null)
                    setList(response?.data?.attendeeList || [])
                    setAllowAttendeeMail(response?.data?.allowAttendeeMail || false);
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const sendMail = () => {
        const headers = {
            Accept: "*/*",
            "Content-Type": "application/json",
        };

        axios
            .post(
                apiBaseUrl3 + apiUrl.sendEventAttendeeList,
                {
                    "_inapprequesttoken": slug,
                    "secureRequestVO": {
                        "cardId": 0,
                        "cardType": "BUSINESS",
                        "forumType": "BUSINESS_FORUM",
                        "userId": 0
                    }
                },
                {
                    headers: headers,
                }
            )
            .then((response) => {
                toast.success("Mail Sent Successfully!")
            })
            .catch((error) => {
                console.error("Error:", error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        if (slug) {
            getDetails();
        }
    }, [slug]);

    const validateForm = (data) => {
        const errors = {};

        if (!data.charges) {
            errors.charges = "Event Charges is required";
        }

        if (!data?.disclaimerText) {
        } else if (data?.disclaimerText?.length > 2000) {
            errors.disclaimerText = "Disclaimer cannot exceed 2000 characters";
        }

        return errors;
    };

    const valueChange = (e) => {
        if (e.target.name === "availableQuantity" || e.target.name === 'eventCharges') {
            const numericValue = e.target.value.replace(/[^0-9]/g, "");
            setFormData({
                ...formData,
                [e.target.name]: numericValue,
            });
        } else {
            setFormData({
                ...formData,
                [e.target.name]: e.target.value,
            });
        }
    }

    const saveData = async () => {
        const data = {
            charges: formData.eventCharges,
            numberOfParticipants: formData.availableQuantity,
            disclaimerText: formData.disclaimerText,
            eventIdToBeUpdated: formData.userEventId,
            _inapprequesttoken: slug,
            "secureRequestVO": {
                "cardId": 0,
                "cardType": "BUSINESS",
                "forumType": "BUSINESS_FORUM",
                "userId": 0
            }
        }

        const validationErrors = await validateForm(data);
        if (Object.keys(validationErrors).length === 0) {
            setLoading(true)
            const headers = {
                accept: "*/*",
                "Content-Type": "application/json",
            };

            axios
                .post(
                    apiBaseUrl3 + apiUrl.saveEventInfo,
                    {
                        ...data
                    },
                    {
                        headers: headers,
                    }
                )
                .then((response) => {
                    if (response?.data?.responseType === "SUCCESS") {
                        setRegisterStep(0)
                        setAtendeeList(false)
                        setNotificationist(false)
                        toast.success("Event payment details updated successfully!")

                    } else {
                        toast.error(
                            "Something went wrong please try again after sometime "
                        );
                    }
                })
                .catch((error) => {
                    toast.error("Something went wrong please try again after sometime ");
                })
                .finally(() => {
                    setLoading(false);
                });

        } else {
            for (const [key, value] of Object.entries(validationErrors)) {
                toast.error(value);
            }
        }
    }

    const totalAmount = list.reduce((sum, attendee) => sum + attendee.amountPaid, 0);
    const totalQU = list.reduce((sum, attendee) => sum + attendee.quantity, 0);

    const downloadExcel = () => {

        const data = list.map((res) => {
            return {
                Name: res?.attendeeName || '',
                Quantity: res?.quantity || 0,
                Amount: res?.amountPaid || 0,
                Invoice: res?.invoiceNumber || 0,
                Date: res?.enrollmentDate || '',
            }
        })

        const summaryRow2 = {
            Name: '',
            Quantity: '',
            Amount: '',
            Invoice: '',
            Date: ''
        };

        const summaryRow = {
            Name: 'Total',
            Quantity: totalQU,
            Amount: totalAmount,
            Invoice: '',
            Date: '',
        };

        const finalData = [...data, summaryRow2, summaryRow];

        const worksheet = XLSX.utils.json_to_sheet(finalData);

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

        XLSX.writeFile(workbook, "Attendee Data.xlsx");
    };

    return (
        <>
            {loading && (
                <div className="loading_main_div">
                    <div className="loader_new">
                    </div>
                </div>
            )}
            {!loading && <>
                <div className="edit-event-page">
                    {registerStep > 0 && <div class="header">Payment Setup </div>}
                    {attendeeList && <div class="header">Attendee Data </div>}
                    {notificationList && <div class="header">Notifications </div>}
                    {registerStep === 0 && !attendeeList && !notificationList &&
                        <>
                            <div class="header">Event Dashboard</div>
                            <div class="menu">
                                <div class="menu-item" onClick={() => {
                                    setNotificationist(true)
                                }}>
                                    <span> <i class="fa fa-bell-o"></i> Notifications</span> <i class="fa fa-chevron-right"></i>
                                </div>
                                <div class="menu-item" onClick={() => {
                                    setRegisterStep(1)
                                }}>
                                    <span><i class="fa fa-usd" aria-hidden="true"></i> Payment Setup</span>  <i class="fa fa-chevron-right"></i>
                                </div>
                                <div class="menu-item" onClick={() => {
                                    setAtendeeList(true)
                                }}>
                                    <span> <i class="fa fa-users"></i> Attendee Data </span><i class="fa fa-chevron-right"></i>
                                </div>
                            </div>
                        </>
                    }
                    {(notificationList || attendeeList || registerStep > 0) &&
                        <div style={{ padding: "20px", display: "flex", alignItems: 'center', gap: '5px' }}>
                            <i class="fa fa-arrow-left" aria-hidden="true" style={{ fontSize: "25px", cursor: "pointer" }}
                                onClick={() => {
                                    setRegisterStep(0)
                                    setAtendeeList(false)
                                    setNotificationist(false)
                                }}
                            ></i> <span>Return to dashboard</span>
                        </div>
                    }

                    {notificationList && <>
                        <div style={{ padding: "0px 10px" }}>

                            {details?.eventNotification?.length > 0 ? (<>{details?.eventNotification?.map((res, index) =>
                                <div style={{ padding: "10px", marginBottom: "20px", border: "solid 1px #ddd", borderRadius: "10px", backgroundColor: "#f8f8f8" }}>
                                    <strong >{res?.title}</strong>
                                    {res?.description && <p class="notification-desc">{res?.description} </p>}
                                </div>
                            )}</>) : (<div>
                                <p className="no-data-app">Sorry, no information found</p>

                            </div>)}

                        </div>
                    </>}
                    {attendeeList &&
                        <>
                            <h5 style={{ textAlign: "center", marginBottom: "15px" }}>Attendee List</h5>
                            <hr />
                            <table class="table table-bordered ">
                                <thead>
                                    <tr>
                                        <th scope="col">Name</th>
                                        <th scope="col">Quantity</th>
                                        <th scope="col">Amount</th>
                                        <th scope="col">Invoice</th>
                                        <th scope="col">Date</th>
                                    </tr>
                                </thead>
                                {list?.length > 0 && <tbody>
                                    {list?.map((res, index) =>
                                        <tr key={index}>
                                            <td scope="row">{res?.attendeeName}</td>
                                            <td>{res?.quantity}</td>
                                            <td>{res?.amountPaid}</td>
                                            <td>{res?.invoiceNumber}</td>
                                            <td>{res?.enrollmentDate}</td>
                                        </tr>
                                    )}
                                    <tr class="table-active">
                                        <th >Total</th>
                                        <td>{totalQU}</td>
                                        <td>{totalAmount}</td>
                                        <td></td>
                                    </tr>
                                </tbody>}

                            </table>
                            {list?.length == 0 && <div>
                                <p className="no-data-app">Sorry, no information found</p>
                            </div>}
                            {allowAttendeeMail && list?.length > 0 &&
                                <div style={{ marginTop: "20px", padding: "10px" }}>
                                    <button className="btn btn-purple w-100" onClick={() => downloadExcel()}>Download Excel</button>
                                </div>
                            }
                        </>
                    }
                    {registerStep > 0 &&
                        <>
                            {formData && <>
                                <div class="container mt-4">
                                    <label class="form-label fw-bold">Event Charges</label>
                                    <div class="mb-3">
                                        <input
                                            type="number"
                                            class="form-control"
                                            placeholder="Event Charges"
                                            name={"eventCharges"}
                                            value={formData?.eventCharges}
                                            onChange={valueChange}
                                        />
                                    </div>
                                    <label class="form-label fw-bold">Total number of tickets</label>
                                    <div class="mb-3">
                                        <input
                                            type="number"
                                            class="form-control"
                                            placeholder="Total number of tickets"
                                            name={"availableQuantity"}
                                            value={formData?.availableQuantity}
                                            onChange={valueChange}
                                        />
                                    </div>
                                    <label class="form-label fw-bold">Available tickets</label>
                                    <div class="mb-3">
                                        <input
                                            type="number"
                                            class="form-control"
                                            placeholder="Available tickets"
                                            name={"ticketsAvailable"}
                                            value={details?.ticketsAvailable}
                                            disabled
                                        />
                                    </div>
                                    <label class="form-label fw-bold">Total sold tickets</label>
                                    <div class="mb-3">
                                        <input
                                            type="number"
                                            class="form-control"
                                            placeholder="Total sold tickets"
                                            name={"ticketsSold"}
                                            value={details?.ticketsSold}
                                            disabled
                                        />
                                    </div>
                                    <label class="form-label fw-bold">Disclaimer </label>
                                    <div class="mb-3">
                                        <textarea
                                            rows={5}
                                            type="text"
                                            class="form-control"
                                            placeholder="Disclaimer"
                                            name={"disclaimerText"}
                                            value={formData?.disclaimerText}
                                            onChange={valueChange}
                                        />
                                    </div>
                                    <p>   <a target="_blank" href="https://hello.listy.city/pricing-page/">*Terms and Conditions Apply</a></p>

                                    <div className="col-12">
                                        <button className="btn btn-purple w-100" onClick={() => saveData()}>Save</button>
                                    </div>
                                </div>
                            </>}
                            {
                                !formData && <div>
                                    <p className="no-data-app">Sorry, no information found</p>
                                </div>
                            }
                        </>
                    }
                </div>
            </>
            }
        </>
    )
}

export default EventEditApp;