import { listyCityMailto, websiteData } from "../important/commanFunction";

const PrivacyPolicy = () => {
  return (
    <>
      <section className="about">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h1 className="text-white">Privacy Policy</h1>
            </div>
          </div>
        </div>
      </section>

      <div className="privacy-policy">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3>1. Introduction</h3>
              <p>Welcome to listy.city a portal run by Neumatrix Technologies Private Limited (“listy”). listy is
                committed to respecting your online privacy and recognizes your need for appropriate protection
                and management of any information you share with listy on listy.city (the "Website") and the
                listy city App.</p>
              <h3>2. Scope</h3>
              <p>This privacy policy (“Policy”) is an electronic record in the form of an electronic contract
                formed under the Information Technology Act, 2000 and the rules made thereunder. This Policy
                does not require any physical, electronic or digital signature. This Policy is a legally binding
                document between you and listy. The terms of this Policy will be effective upon your acceptance
                of the same by use of the website and will govern the relationship between you and listy for
                your use of the Website and the app.</p>
              <h3>3. Website Details </h3>
              <p>This Website is operated by Neumatrix Technologies Private Limited , For listy.city website, you
                could contact us by mailing us <a
                  href={listyCityMailto}>{websiteData.email}</a></p>
              <h3>4. Consent</h3>
              <p>By mere use of the Website, you expressly consent to listy’s use and disclosure of your Personal
                Information in accordance with this Policy. If you do not agree with the terms of this Policy,
                please do not use this Website. This Policy shall be deemed to be incorporated into the Terms of
                Use of the Website and shall be read in addition to the Terms of Use.</p>
              <h3>5. What Information Is Collected</h3>
              <ul>
                <li>The Information Technology (Reasonable Security Practices and Procedures and Sensitive
                  Personal
                  Data or Information) Rules, 2011 defines “Personal Information” as any information that
                  relates
                  to a natural person, which, either directly or indirectly, in combination with other
                  information
                  available or likely to be available with a body corporate, is capable of identifying such
                  person.</li>
                <li>For the purpose of this Policy, the term “Personal Information” shall mean any information
                  you
                  provide to listy and shall include, without limitation your name, phone number, your e-mail
                  address, your financial information such as bank account or credit card or debit card or
                  other
                  payment details, your email ID and password.</li>
                <li>For the purpose of this Policy, the term “Non-Personal Information” shall mean information
                  that
                  does not specifically identify an individual but includes information from you, such as the
                  type
                  of internet browser you use, mobile device you use, your computer or your mobile device’s
                  unique
                  device ID, the URL of the previous website you visited, your computer’s operating system,
                  your
                  Internet Service Provider (ISP) and your Internet Protocol (IP) address. listy may also
                  collect
                  Non-Personal Information that you voluntarily provide, such as information included in
                  response
                  to a questionnaire or a survey conducted by listy.</li>
              </ul>
              <h3>6. Collection of Information</h3>
              <ul>
                <li>listy may collect information from you when you register on the Website through your
                  Facebook
                  account or e-mail account, when you use the features on the Website, when you participate in
                  surveys conducted by listy on the Website and when you carry out transactions on the Website
                </li>
                <li>listy may use cookies to monitor the Website usage including, without limitation, to provide
                  useful features to simplify your experience when you return to the Website, like remembering
                  your login ID and certain Personal Information and to deliver relevant content based on your
                  preferences, usage patterns and location.</li>
                <li>You may be also required to provide your credit/debit card details. listy will use this
                  information only for billing purposes. For your convenience and if permitted by you, listy
                  will
                  save the billing information in the event you would like to carry out any other transaction
                  on
                  the Website again.</li>
                <li>When you access the Website through a mobile device, you will have the option of downloading
                  the
                  mobile application of listy. In case you download the mobile application you will be asked
                  if
                  you would want to share your contact list with listy. You may choose not to share your
                  contact
                  list with listy, however in case you chose to share your contact lit with listy, listy shall
                  have access to and store your contact list.</li>
                <li>You may use the Website without providing listy any Personal Information about yourself.
                  However, you may not be able to access certain services of the Websites in case you choose
                  to do
                  so.</li>
              </ul>
              <h3>7. Use of Information</h3>
              <p>listy uses your Personal and Non-Personal Information for certain purposes including but not
                limited to:</p>
              <ul>
                <li>Request access to your personal data. The right to access, update or delete the information
                  we
                  have on you. Whenever made possible, you can access, update or request deletion of your
                  personal
                  data directly within your account settings section. If you are unable to perform these
                  actions
                  yourself, please contact us to assist you at <a
                    href={listyCityMailto}>{websiteData.email}</a></li>
                <li>Object to processing of your personal data. This right exists where we are relying on a
                  legitimate interest as the legal basis for our processing and there is something about your
                  particular situation, which makes you want to object to our processing of your personal data
                  on
                  this ground. You can raise an objection by contacting us at <a
                    href={listyCityMailto}> {websiteData.email}</a>.</li>
                <li>Request erasure / deletion of your personal data. You have the right to ask us to delete or
                  remove personal data when there is no good reason for us to continue processing it. If you
                  request the deletion of your data, we may not be able to provide you with access to certain
                  specific functionalities of the service. You can request the deletion of your personal data
                  by
                  sending us an email at <a href={listyCityMailto}> {websiteData.email}
                  </a>
                  with the subject "Delete Account Data".</li>
              </ul>
              <h3>8. Limitation of Liability</h3>
              <ul>
                <li>listy is not responsible for any breach of security or for any actions or inactions of any
                  third
                  parties that receive your Personal Information.</li>
                <li>Notwithstanding anything contained in this Policy or elsewhere, listy shall not be held
                  responsible for any loss, damage or misuse of your Personal Information, if such loss,
                  damage or
                  misuse is attributable to a Force Majeure Event (as defined below).</li>
                <li>A “Force Majeure Event” shall mean any event that is beyond the reasonable control of listy
                  and
                  shall include, without limitation, sabotage, fire, flood, explosion, acts of God, civil
                  commotion, strikes, lockouts or industrial action of any kind, riots, insurrection, war,
                  acts of
                  government, computer hacking, civil disturbances, unauthorized access to computer data and
                  storage device, computer crashes, breach of security and encryption, and any other similar
                  events not within the control of listy and which listy is not able to overcome.</li>
              </ul>
              <h3>9. Diversity And Equal opportunity Policy</h3>
              <p>We have also opted for diversity and equal opportunity policy.</p>
              <h3>10. Opt-Out</h3>
              <p>You have the option to 'opt-out' of all listy’s newsletters and other general e-mail marketing
                communications by way of links provided at the bottom of each mailer. listy respects your
                privacy and in the event that you choose to not receive such mailers, it shall take all adequate
                steps to remove you from such lists.</p>
              <h3>11. Changes to this Policy</h3>
              <p>listy reserves the right to update, change or modify this Policy at any time. The Policy shall
                come to effect from the date of such update, change or modification.</p>
              <h3>12. Grievance Officer</h3>
              <p>In accordance with Information Technology Act 2000 and rules made there under and the Consumer
                Protection (E-Commerce) Rules 2020, the name and contact details of the Grievance Officer are
                provided below</p>
              <p>Name: Charu,</p>
              <p>E-mail: <a href={listyCityMailto}> {websiteData.email}</a></p>
              <h3>13. How to Contact Us</h3>
              <p>If you have questions or concerns about this Policy, please contact us at <a
                href={listyCityMailto}> {websiteData.email}</a>.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PrivacyPolicy;
