import { websiteData } from "../important/commanFunction";

const BusinessHelpPage = () => {
  return (
    <section className="about">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-7">
            <h1>
              Business Help
            </h1>
            <p>
              Looking to register your business with listy city – great
              choice! </p>
            <p>
              Fill in your details in the Register Business form on
              website or the mobile app.
            </p>
            <p>
              Once you have submitted your details – we will verify them
              and post approval your business will be available on the
              app.
            </p>
            <p>
              Once your business is approved you can also create your
              online and offline events.
            </p>

            <p>
              Get listy, get exploring!
            </p>
            <p>
              For more any concerns contact us on
            </p>
            <p>
              {websiteData.email}
            </p>

          </div>
          <div className="col-12 col-lg-5">
            <img src="images/about-image.png" className="w-100" alt="About Image" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default BusinessHelpPage;
