import { Link } from "react-router-dom";
import {
  pageRoutes,
} from "../important/commanFunction";
import { useSelector } from "react-redux";

const RegisterPageStep3 = ({ registerStep }) => {
  const eventData = useSelector((state) => state.eventData);
  return (
    <><form className="business-stepper-form row g-3">
      <div className="col-12 d-flex flex-column align-items-center gap-3">
        <img src="/images/images/qr-scanner.png" alt="QR Code" className="d-none d-md-block" />
        {registerStep === 3 &&
          <div className="all-set d-none d-md-flex align-items-center justify-content-center">
            <img src="/images/images/check.svg" alt="Check Icon" />
            <span>You’re all set!</span>
          </div>}
        <h4 className="d-none d-md-block">Scan the QR Code to download the app.</h4>
        <h4 className="d-block d-md-none">Download the App & Start Exploring Today</h4>

        {registerStep === 4 && (
          <h4>Heyyy, seems like you are already Enroll with us.
          </h4>
        )}
        {eventData?.workFlowVO?.linkUrl &&
          <p>
            Thanks for expressing your interest in this event. <a href={eventData?.workFlowVO?.linkUrl} > <strong>Click here </strong></a> to get more details about this event.
          </p>
        }

        {!eventData?.workFlowVO?.linkUrl &&
          <p>
            Thanks for expressing your interest in this event. We will send you the event details on your registered mail/ whatsapp shortly. Please make sure to check your spam folder so you don't miss out on our mail :{")"}
          </p>
        }

        {eventData?.eventLink &&
          <p>
            This is an Online event. Click below to join this event.
            <br />
            <a href={eventData?.eventLink} > {eventData?.eventLink} </a>
            <br />
            This event link is also available in the listy city app.
          </p>
        }

        <p >
          Find more such events on the <strong>Listy City</strong> app and create your own. It’s easy to connect with your customers on Listy. You can <span className="qr1"> personalize </span> your profile, get your own <span className="qr2"> webpage, </span> and create exciting <span className="qr3">events.</span>
        </p>
      </div>
      <div className="col-12">
        <Link className="nav-link btn btn-purple d-block" to={pageRoutes?.addEvent} >Launch your event</Link>
      </div>
    </form>
    </>
  );
};

export default RegisterPageStep3;
