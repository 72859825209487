import { listyCityMailto, websiteData } from "../important/commanFunction"

const TermsPage = () => {
    return (
        <>
            <section className="about">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center">
                            <h1 className="text-white">Terms of Use</h1>
                        </div>
                    </div>
                </div>
            </section>
            <div className="terms-of-use py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h3>THIS DOCUMENT IS AN ELECTRONIC RECORD IN TERMS OF THE INFORMATION TECHNOLOGY ACT, 2000 AND RULES
                                MADE THEREUNDER. THIS ELECTRONIC RECORD IS GENERATED BY A COMPUTER SYSTEM AND DOES NOT REQUIRE
                                ANY PHYSICAL OR DIGITAL SIGNATURES.</h3>
                            <h3>THIS DOCUMENT IS PUBLISHED IN ACCORDANCE WITH THE PROVISIONS OF RULE 3(1) OF THE INFORMATION
                                TECHNOLOGY (INTERMEDIARIES GUIDELINES) RULES, 2011 THAT REQUIRES PUBLISHING THE RULES AND
                                REGULATIONS, PRIVACY POLICY AND TERMS OF USE FOR ACCESS OR USAGE OF THIS WEBSITE.</h3>
                            <p>The domain name listy.city (hereinafter referred to as the “Website”) and the listy city app are
                                owned by Neumatrix Technologies Private Limited, a company incorporated under the Companies Act,
                                1956. The Website is a local discovery platform highlighting local businesses and events in
                                various cities in India.</p>
                            <p>listy reserves the right, at its sole discretion, to change, modify, add or remove portions of
                                these Terms of Use (“the Terms”), at any time without any prior written notice to you. It is
                                your sole responsibility to review these Terms periodically for updates or changes. Your
                                continued use of the Website following the posting of changes shall be deemed to mean that you
                                accept and agree to the revisions. As long as you comply with these Terms, listy grants you a
                                personal, non-exclusive, non-transferable, limited privilege to access and use the Website.</p>
                            <h3>2. Membership Eligibility</h3>
                            <p>Use of the Website is available only to persons who can enter into legally binding contracts
                                under the Indian Contract Act, 1872. Persons who are "incompetent to contract" within the
                                meaning of the Indian Contract Act, 1872 including minors, un-discharged insolvents and persons
                                of unsound mind are not eligible to use the Website. Any person under the age of 18 shall not
                                register on the Website and shall not transact on or use the Website. If a minor wishes to use
                                or transact on the Website, such use or transaction may be made by the minor’s legal guardian or
                                parents on the Website. listy reserves the right to terminate any person’s membership and/or
                                refuse to provide such person with access to the Website if it is brought to listy’s notice or
                                if it is discovered that such person is not eligible to use the Website.</p>
                            <h3>3. Acceptance of Terms</h3>
                            <p>Please carefully read these Terms. By accessing or using the Website, you are agreeing to these
                                Terms and concluding a legally binding contract with listy. You may not use the Website if you
                                do not accept the Terms or unable to be bound by the Terms.</p>
                            <p>In order to use the Website, you must first agree to the Terms. You can accept the Terms by</p>
                            <p>Logging into listy your registered phone number. Should you do so , you understand and agree that
                                listy will treat your use of the Website as acceptance of the Terms from that point onwards.</p>
                            <h3>4. Opening an Account</h3>
                            <p>In order to use the App, you will have to create an account on the Website (“the Account”), which
                                can be done either by signing up through your registered phone number. As part of the
                                application, you will be required to provide an phone number which is to be used for logging
                                into the Account in the future. You shall not transfer or share your Account password with
                                anyone, or create more than one Account.</p>
                            <h3>5. Usernames/Passwords</h3>
                            <p>The confidentiality of your username and password is to be maintained by you and in case of any
                                misappropriation or unauthorized access of the Account you agree to communicate the same
                                promptly to listy. Any information regarding this will be sent to the e-mail address which was
                                used at the time of sign up.</p>
                            <h3>6. Agreement to receive Mails &amp; Phone calls</h3>
                            <p>You hereby by way of accepting these Terms consent to the receipt of communication from us by way
                                of e-mails, newsletters, WhatsApp messages &amp; Phone calls.</p>
                            <h3>7. Links to Third Party Websites</h3>
                            <p>The Website may contain links and interactive functionality interacting with the websites of
                                third parties. listy is not responsible for and has no liability for the functionality, actions,
                                inactions, privacy settings, privacy policies, terms, or content of any such website. Before
                                enabling any sharing functions to communicate with any such website or otherwise visiting any
                                such website, listy strongly recommends that you review and understand the terms and conditions,
                                privacy policies, settings, and information-sharing functions of each such third-party website.
                            </p>
                            <h3>8. Use of the Website</h3>
                            <p>You agree, undertake and covenant that, during the use of the Website, you shall not host,
                                display, upload, modify, publish, transmit, update or share any information that:</p>
                            <li>belongs to another person or entity and to which you do not have any right.</li>
                            <li>is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, pedophilic,
                                libelous, invasive of another person's privacy, hateful or racially or ethnically objectionable,
                                or encouraging money laundering or gambling, or otherwise unlawful in any manner whatsoever.
                            </li>
                            <li>is misleading in any way.</li>
                            <li>involves the transmission of "junk mail", "chain letters", or unsolicited mass mailing or
                                "spamming".</li>
                            <li>infringes upon or violates any third party's rights including, but not limited to, intellectual
                                property rights, rights of privacy (including without limitation unauthorized disclosure of a
                                person's name, e-mail address, physical address or phone number) or rights of publicity.</li>
                            <li>provides instructional information about illegal activities such as violating someone's privacy,
                                or providing or creating computer viruses.</li>
                            <li>tries to gain unauthorized access or exceeds the scope of authorized access to the Website or to
                                profiles, blogs, communities, account information, bulletins, or other areas of the Website or
                                solicits passwords or personally identifying information for commercial or unlawful purposes
                                from other users of the Website.</li>
                            <li>engages in commercial activities without listy’s prior written consent such as engages in
                                contests, sweepstakes, barter, advertising etc.</li>
                            <li>interferes with another user’s use of the Website.</li>
                            <li>refers to any website or URL that, in listy’s sole discretion, contains material that is
                                inappropriate for the Website or any other website, contains content that would be prohibited or
                                violates the letter or spirit of these Terms.</li>
                            <li>deceives or misleads the addressee/ users about the origin of such messages or communicates any
                                information which is grossly offensive or menacing in nature.</li>
                            <li>contains software viruses or any other computer codes, files or programs designed to interrupt,
                                destroy or limit the functionality of any computer resource; or contains any trojan horses,
                                worms or other computer programming routines that may damage, detrimentally interfere with,
                                diminish value of, covertly intercept or steal any system, data or personal information.</li>
                            <h3>9. Your Reviews</h3>
                            <li>The Website may allow you to post certain content, data or information of your own, such as
                                reviewing your allowing you to share your experience and views about a particular post, post
                                your comments and reviews in relation to contents on the Website on specific pages of the
                                Website, as well as submit any suggestions, comments, questions or other information to listy
                                using the Website (collectively referred to "User Content").</li>
                            <li>You, being the originator of the User Content, are responsible for the User Content that you
                                upload, post, publish, transmit or otherwise make available on the Website. You represent that
                                you have obtained all relevant consents and approvals in order to post any User Content. You
                                further represent that all such User Content will be in accordance with applicable law. You
                                acknowledge that listy does not endorse any User Content on the Website and is not responsible
                                or liable for any User Content. listy reserves the right to disable access to the User Content
                                on the Website.</li>
                            <li>You hereby grant listy a perpetual, non-revocable, worldwide, royalty-free and sub-licensable
                                right and license to use, copy, distribute, display, publish, transmit, make available,
                                reproduce, modify, adapt the User Content and create derivate works of the User Content. You
                                represent and warrant that you own or otherwise control all of the rights to the User Content
                                that you post or that you otherwise provide on or through the Website; and that, as at the date
                                that the User Content is posted or submitted on the Website: (i) the User Content is accurate;
                                (ii) use of the User Content you supply does not breach these Terms; and (iii) that such User
                                Content is lawful.</li>
                            <li>You further represent and warrant that while posting any User Content on the Website you shall
                                not use any offensive, libelous, derogatory, hateful or racially or ethnically objectionable
                                language. Further, you shall not post any content on the Website that is obscene, pornographic,
                                constitutes an “indecent representation of women” as provided in The Indecent Representation of
                                Women (Prohibition) Act, 1986.</li>
                            <h3>10. Limitation of Liability</h3>
                            <p>You hereby acknowledge that listy shall not be held liable to you for any special, consequential,
                                incidental, and exemplary or punitive damages, or loss of profit or revenues. We are also not
                                liable under any circumstances for damages arising out or related in any way to your inability
                                to access, or your difficulty in accessing the Website, any bugs, viruses, trojan horses, or the
                                like, which may be transmitted to or through the Website by any third party, any loss of your
                                data or content from the services, your failure to keep your password or Account details secure
                                and confidential. listy shall not be liable under any circumstances for damages arising out of
                                or in any way related to products, services and/or information offered or provided by
                                third-party vendors accessed through the Website.</p>
                            <h3>11. Disclaimer of Warranties</h3>
                            <p>THE WEBSITE , AND THE APP AND ALL INFORMATION, CONTENT, MATERIALS AND SERVICES INCLUDED ON OR
                                OTHERWISE MADE AVAILABLE TO YOU THROUGH THE WEBSITE (COLLECTIVELY, THE "CONTENTS") ARE PROVIDED
                                BY listy ON AN "AS IS," "AS AVAILABLE" BASIS, WITHOUT REPRESENTATIONS OR WARRANTIES OF ANY KIND.
                                listy MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE
                                OPERATION OF THE WEBSITE, THE ACCURACY OR COMPLETENESS OF THE CONTENTS AND THE ACCURACY OF THE
                                INFORMATION. listy SHALL HAVE NO RESPONSIBILITY FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS
                                OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY CONTENT, MATERIALS, DOCUMENT OR INFORMATION. YOU
                                EXPRESSLY AGREE THAT THE USE OF THE WEBSITE IS AT YOUR SOLE RISK. listy WILL NOT BE LIABLE FOR
                                ANY DAMAGES OF ANY KIND ARISING FROM THE USE OF THE WE SITE OR THE CONTENTS INCLUDING, WITHOUT
                                LIMITATION, DIRECT, INDIRECT, CONSEQUENTIAL, PUNITIVE, AND CONSEQUENTIAL DAMAGES, UNLESS
                                OTHERWISE SPECIFIED IN WRITING. TO THE FULL EXTENT PERMITTED BY LAW, listy DISCLAIMS ANY AND ALL
                                REPRESENTATIONS AND WARRANTIES WITH RESPECT TO THE WEBSITE AND ITS CONTENTS, WHETHER EXPRESS OR
                                IMPLIED, INCLUDING, WITHOUT LIMITATION, WARRANTIES OF TITLE, MERCHANTABILITY, AND FITNESS FOR A
                                PARTICULAR PURPOSE OR USE.</p>
                            <h3>12. Indemnification</h3>
                            <p>You shall indemnify and hold harmless listy, its owner, licensee, affiliates, subsidiaries, group
                                companies (as applicable) and their respective officers, directors, agents, and employees, from
                                any and all claims or demands, or actions including reasonable attorneys' fees, made by any
                                third party or penalty imposed due to or arising out of your breach of these Terms, Privacy
                                Policy and other policies, or your violation of any law, rules or regulations or the rights
                                (including infringement of any intellectual property rights) of a third party.</p>
                            <h3>13. Content and Intellectual Property Rights</h3>
                            <p>listy solely and exclusively owns copyrights, trademarks, service marks, logos, trade names, and
                                other intellectual and proprietary rights associated with our Services and Contents and is
                                protected under Indian law.</p>
                            <p>You hereby acknowledge that the Services constitute original works and have been developed,
                                compiled, prepared, revised, selected, and arranged by us and others through the application of
                                methods and standards of judgment developed and applied through the expenditure of substantial
                                time, effort, and money and constitutes valuable intellectual property of us and such others.
                                You thereby agree to protect the proprietary rights of listy during and after the term of this
                                agreement. You may not selectively download portions of the website without retaining the
                                copyright notices You may download material from the website only for your own personal use and
                                for no commercial purposes whatsoever.</p>
                            <p>Any infringement shall lead to appropriate legal proceedings against you at appropriate forum for
                                seeking all available remedies under applicable laws of the country.</p>
                            <h3>14. Termination of Access to your Services</h3>
                            <p>The Account can be terminated at any time by:</p>
                            <li>You by ceasing to use the Website.</li>
                            <li>listy, in its sole discretion for any reason or no reason including your violation of these
                                Terms or lack of use of Services. You acknowledge that the termination of services may be
                                affected without any prior notice, and listy may immediately deactivate or delete your account
                                and all related information and/or bar any further access to your account or the services.
                                Further, you agree that listy shall not be liable for any discontinuation or termination of
                                services by any third party.</li>
                            <h3>15. Privacy Policy</h3>
                            <p>Please review our Privacy Policy, which also governs your visit to the Website, to understand our
                                practices. The personal information / data provided to us by you during the course of usage of
                                the Website will be treated as strictly confidential and in accordance with the Privacy Policy
                                and applicable laws and regulations. If you object to your information being transferred or
                                used, please do not use the Website.</p>
                            <h3>16. Governing Law</h3>
                            <p>These Terms shall be governed by and interpreted and construed in accordance with the laws of
                                India. The place of jurisdiction shall exclusively be in Delhi, India. In the event of any
                                dispute arising out of these Terms the same shall be settled by a binding arbitration conducted
                                by a sole arbitrator, appointed jointly by both parties and governed by the Arbitration and
                                Conciliation Act, 1996. The venue of arbitration shall be Delhi, India.</p>
                            <h3>17. Grievance Redressal Mechanism</h3>
                            <p>In accordance with Information Technology Act 2000 and rules made there under and the Consumer
                                Protection (E-Commerce) Rules, 2020, the name and contact details of the Grievance Officer are
                                provided below:</p>
                            <p>Name: Charu,</p>
                            <p>E-mail: <a href={listyCityMailto}> {websiteData.email}</a></p>
                            <h3>18. How to Contact Us</h3>
                            <p>If you have questions or concerns about this Policy, please contact us at <a
                                href={listyCityMailto}> {websiteData.email}</a>.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TermsPage