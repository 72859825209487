import { Link } from "react-router-dom";
import {
  pageRoutes,
} from "../important/commanFunction";

const RegisterPageStep3 = ({ registerStep }) => {
  return (
    <>
      <form className="business-stepper-form row g-3">
        <div className="col-12 d-flex flex-column align-items-center gap-3">
          <img src="/images/images/qr-scanner.png" alt="QR Code" className="d-none d-md-block" />
          {registerStep === 3 &&
            <div className="all-set d-none d-md-flex align-items-center justify-content-center">
              <img src="/images/images/check.svg" alt="Check Icon" />
              <span>You’re all set!</span>
            </div>}
          <h4 className="d-none d-md-block">Scan the QR Code to download the app.</h4>
          <h4 className="d-block d-md-none">Download the App & Start Exploring Today</h4>

          {registerStep === 4 && (
            <>
              <h4>Heyyy, seems like you are already registered with us.
              </h4></>
          )}
          <p >
            It’s easy to connect with your customers on Listy. You can <span className="qr1"> personalize </span> your profile, get your own <span className="qr2">  webpage, </span> and create exciting <span className="qr3"> events  </span>for your business.
          </p>
        </div>
        <div className="col-12">
          <Link className="nav-link btn btn-purple d-block d-md-none" to={pageRoutes?.addEvent} >launch your event</Link>
        </div>
      </form>
    </>
  );
};

export default RegisterPageStep3;
