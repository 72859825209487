import { appDownloadUrl, qrCodeImageUrl, qrCodeMobileImageUrl } from "../important/commanFunction"

const QrCodeSection = () => {
  return (
    <section >
      <div className="container">
        <div className="row ">
          <div className="col-12">
            <div className="download-app p-0">
              <div className="download-app-content">
                <h3><span>Download the App &</span> <br />Start Exploring Today</h3>
                <p>Don’t miss out on all the local events and <br />services around you. Download the app and
                  <br />stay connected with your city!</p>
                <a href={appDownloadUrl} target="_blank" className="btn btn-white">Get The App</a>
              </div>
              <div className="download-app-image">
                <img src={qrCodeImageUrl} alt="QR Code" className="d-none d-md-block" />
                <img src={qrCodeMobileImageUrl} className="d-block d-md-none" alt="QR Code" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default QrCodeSection