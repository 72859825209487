import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { apiBaseUrl, apiUrl, pageRoutes } from "../important/commanFunction";
import moment from "moment";
import { addEventData } from "../actions/siteActions";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

const DetailsPage = () => {
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [details, setDetails] = useState({});
  const dispatch = useDispatch();
  const { type, slug } = useParams();

  const getDetails = () => {
    const headers = {
      Accept: "*/*",
      "Content-Type": "application/json",
    };

    axios
      .post(
        apiBaseUrl + apiUrl.getDetailsByType,
        { slug, type },
        {
          headers: headers,
        }
      )
      .then((response) => {
        if (response?.data?.responseType === "SUCCESS") {
          setDetails(response?.data?.cardDetailsVO);
          dispatch(addEventData(response?.data?.cardDetailsVO || {}))
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (type && slug) {
      getDetails();
    }
  }, [slug, type]);

  const iconStyle = { color: "#8254E3", fontSize: "33px" }

  return (
    <>
      {loading && (
        <div className="loading_main_div">
          <div className="loader_new">
          </div>
        </div>
      )}
      {!loading && !details?.cardDetailId && (
        <div className="data_not_found">
          <p>Record not found</p>
        </div>
      )}
      {!loading && details?.cardDetailId && (
        <div className="main-details-page-div">
          <div className="col-md-8 main-details-page-content" >
            <div className="head_details">
              <span>{details?.title}</span>
              <a href={details?.images[0]} className="location mt-2" target="_blank"> <img src={details?.images[0]} alt="Event Cover Image" /></a>
            </div>
            {(details?.startDate || details?.startDate) &&
              <div className="time_details">
                {details?.startDate &&
                  <div className="date-container">
                    <div className="date-box">
                      <div className="month">{details?.startDate ? moment(details?.startDate).format("MMM") : "N/A"}</div>
                      <div className="day">{details?.startDate ? moment(details?.startDate).format("DD") : "N/A"}</div>
                    </div>
                    <div className="date-details">
                      <div className="day-date"> {details?.startDate ? moment(details?.startDate).format("ddd DD MMM") : "N/A"}</div>
                      <div className="time"> {details?.startDate
                        ? `${moment(details?.startDate).format("h:mmA")}`
                        : "N/A"}</div>
                    </div>
                  </div>
                }
                {details?.endDate && details?.startDate && <> To </>}
                {
                  details?.endDate &&
                  <div className="date-container">
                    <div className="date-box">
                      <div className="month">{details?.endDate ? moment(details?.endDate).format("MMM") : "N/A"}</div>
                      <div className="day">{details?.endDate ? moment(details?.endDate).format("DD") : "N/A"}</div>
                    </div>
                    <div className="date-details">
                      <div className="day-date"> {details?.endDate ? moment(details?.endDate).format("ddd DD MMM") : "N/A"}</div>
                      <div className="time"> {details?.endDate
                        ? `${moment(details?.endDate).format("h:mmA")}`
                        : "N/A"}</div>
                    </div>
                  </div>
                }
              </div>
            }
            <div className="description_details">

              {details?.highlights[0] && <p> <strong>This Event is</strong> {details?.highlights[0]}</p>}
              {details?.description && <>

                <p>{details?.description}</p>
              </>}

              {details?.address && <>
                <p ><span className="head_text">Location : </span> {details?.address}
                  {" "}
                  {(details?.latitude == 0 && details?.longitude == 0) ? (
                    <a href={`https://maps.google.com/?q=${details?.address}`} className="location" target="_blank"><img
                      src="/images/images/link-icon.svg" alt="Link Icon" />
                    </a>
                  ) : (
                    <a href={`https://maps.google.com/?q=${details?.latitude},${details?.longitude}`} className="location" target="_blank">
                      <img
                        src="/images/images/link-icon.svg" alt="Link Icon" />

                    </a>
                  )}
                </p>
              </>}
              {(details?.instagramLink || details?.facebookLink || details?.contactNumber || details?.website || details?.email) && (
                <div className="social-media mt-5">
                  <ul className="mt-3">

                    {details?.instagramLink &&
                      <li>
                        <a href={details?.instagramLink} target="_blank">
                          <span style={iconStyle}><i class="fa fa-instagram" aria-hidden="true"></i>
                          </span>
                        </a>
                      </li>
                    }
                    {details?.facebookLink &&
                      <li>
                        <a href={details?.facebookLink} target="_blank">
                          <span style={iconStyle}><i class="fa fa-facebook-square" aria-hidden="true"></i>
                          </span>
                        </a>
                      </li>
                    }

                    {details?.contactNumber &&
                      <li>
                        <a href={`tel:${details?.contactNumber}`}><img src="/images/images/phone-icon.svg" alt="Phone Icon" /></a>
                      </li>
                    }
                    {details?.website &&
                      <li>
                        <a href={details?.website} target="_blank"><img src="/images/images/website.svg" alt="Website Icon" /></a>
                      </li>
                    }

                    {details?.email &&
                      <li >
                        <a href={`mailto:${details?.email}`}>
                          <span style={iconStyle} >
                            <i class="fa fa-envelope" aria-hidden="true"></i>
                          </span>
                        </a>
                      </li>
                    }
                  </ul>
                </div>
              )}

              {details?.contentSource && <p style={{ fontSize: "12px" }}> <br />*{details?.contentSource}</p>}
            </div>
            <div className="price_details">
              <div className="price_details_div">
                {(details?.price || details?.price === 0 || details?.price === "0") && (
                  <h3>{details?.price == 0 ? 'Free' : `₹ ${details?.price}**`}</h3>
                )}

                {type === "business" ? (
                  <Link className="nav-link btn btn-purple" to={pageRoutes.registerBusiness}>Register Your Business</Link>
                ) : (
                  <>
                    {details?.workFlowVO?.workflowType === 'SOLD_OUT' ?
                      (<button className="btn btn-secondary" disabled><strong> {details?.workFlowVO?.title || 'Sold Out'} </strong></button>)
                      :
                      (<Link className="nav-link btn btn-purple" to={`${pageRoutes.enrollNowPage}/${type}/${slug}`}><strong> {details?.workFlowVO?.title || 'Sold Out'} </strong></Link>)}
                  </>
                )}
              </div>
              {details?.price != 0 && details?.price !== "0" && (
                <p style={{ fontSize: "12px" }}>
                  <br />** subject to host or service provider
                </p>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DetailsPage;
