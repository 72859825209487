const GernalHelpPage = () => {
  return (
    <section className="about">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-7">
            <h1>
              General Help
            </h1>
            <p>
              Listy - All things city. Your one stop guide to your city. From a home baker, to a fitness trainer, to yog guru to maths tutor, skating coach to the travel services provider get started on listy to find next-door services. Explore coolest events in your city, the most happening places, local services and a lot more. Register your products and services with listy and find potential customers in and around town. Reach new customers and showcase the best of what you offer. </p>
            <p>
              It also allows creating of offline and online events which can then reach a larger audience. Market your events to community.
            </p>
            <p>
              For more information visit us on our website - https://listy.city. Download the app and get exploring.
            </p>
            <p>
              With Listy, you can:
            </p>
            <p>
              1. Find local events and services
            </p>
            <p>
              2. Register your business and grow
            </p>
            <p>
              3. Create your own events to showcase your work.
            </p>
            <p>
              All this for free!
            </p>
            <p>
              See you onboard
            </p>
            <p>
              -Team Listy
            </p>
          </div>
          <div className="col-12 col-lg-5">
            <img src="images/about-image.png" className="w-100" alt="About Image" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default GernalHelpPage;
