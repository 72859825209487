import { appDownloadUrl, youtubeIframeUrl } from "../important/commanFunction";

const AboutPage = () => {
  return (
    <>
      <section className="about">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h1 className="text-white">About listy city</h1>
            </div>
          </div>
        </div>
      </section>
      <section className="about-content py-5">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6">
              <h2>listy city - Your one stop guide to your city</h2>
              <h4>With listy, you can:</h4>
              <ul>
                <li>Explore local services and coolest events in your city.</li>
                <li>Register your business and reach new customers.</li>
                <li>Create offline and online events to reach a larger audience. Market your events to
                  community.</li>
              </ul>
            </div>
            <div className="col-12 col-md-6">
              <iframe width="100%" height="315" src={youtubeIframeUrl}
                title="listy city app - the coolest new way to reach customers" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
          </div>
        </div>
      </section>

      <section className="about-content py-0 py-md-5">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="about-cta">
                <h4>All this for FREE!!!! <a href={appDownloadUrl} target="_blank">Download the app</a> and get exploring!</h4>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutPage;
