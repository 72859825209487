import axios from "axios";
import { useEffect, useState } from "react";
import {
  apiBaseUrl,
  apiUrl,
  dateSendFormate2,
  imagetypeError,
  isImageType,
  isValidEmail,
  isValidUrl,
  trimString,
} from "../important/commanFunction";
import Select from 'react-select';

import { toast } from "react-toastify";
import cityName from "../important/city.json";
import categoryList from "../important/category.json";
import socialName from "../important/socialName.json";

const RegisterPageStep2 = (props) => {
  const { stepChange, formValue } = props;
  const [loading, setLoading] = useState(false);
  const [tagsList, setTagsList] = useState([]);

  const formatDateTimeLocal = (date) => {
    const pad = (num) => (num < 10 ? '0' : '') + num;
    return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}T${pad(date.getHours())}:${pad(date.getMinutes())}`;
  };

  function getNextDayWithTime(hours, minutes) {
    const nextDay = new Date();
    nextDay.setDate(nextDay.getDate() + 1);
    nextDay.setHours(hours, minutes, 0, 0);
    return formatDateTimeLocal(nextDay);
  }

  const [formInputValue, setFormInputValue] = useState({
    installationKey: formValue?.webInstallationKey,
    contactEmail: formValue?.emailAddress,
    registeredPhoneNumber: formValue?.phoneNumber,
    businessContactNumber: formValue?.phoneNumber,
    countryCode: 91,
    userEventType: 'OFFLINE',
    startDate: getNextDayWithTime(9, 0),
    endDate: getNextDayWithTime(21, 0),
    displayUserNumber: false
  });

  const getMaxDate = () => {
    const today = new Date();
    const maxDate = new Date(today);
    maxDate.setDate(maxDate.getDate() + 30);
    return maxDate.toISOString().split('T')[0];
  };

  const [category, setCategory] = useState([]);
  useEffect(() => {
    setCategory(categoryList?.categoryList)
  }, [categoryList])

  const [googleAddress, setGoogleAddress] = useState({})

  const valueChange = (e) => {
    if (!loading) {
      if (e.target.name === "businessContactNumber" || e.target.name === 'eventCharges' || e.target.name === 'availableQuantity') {
        const numericValue = e.target.value.replace(/[^0-9]/g, "");
        setFormInputValue({
          ...formInputValue,
          [e.target.name]: numericValue,
        });
      } else if (e.target.name === "contactPhoneNumber") {
        let sanitizedValue = e.target.value.replace(/[^0-9+]/g, '');
        if (sanitizedValue.startsWith('+')) {
          sanitizedValue = "+" + sanitizedValue.replaceAll('+', '');
        } else {
          sanitizedValue = sanitizedValue.replaceAll('+', '');
        }
        setFormInputValue({
          ...formInputValue,
          [e.target.name]: sanitizedValue,
        });
      } else {
        setFormInputValue({
          ...formInputValue,
          [e.target.name]: e.target.value,
        });
      }
    }
  };

  const handleSwitchToggle = (e) => {
    const newValue = e.target.checked ? "ONLINE" : "OFFLINE";

    setFormInputValue({
      ...formInputValue,
      userEventType: newValue,
    });
  };

  const [selectedImage, setSelectedImage] = useState(null);

  const fileChange = (e) => {

    const file = e.target.files[0];

    if (file) {
      if (!file.type.startsWith('image/')) {
        toast.error(
          "Please upload an image file."
        );
      }

      if (file.size > 2 * 1024 * 1024) {
        toast.error(
          "File size exceeds 2MB. Please upload a smaller image."
        );
      }

      if (file.size <= 2 * 1024 * 1024 && file.type.startsWith('image/')) {
        setFormInputValue({
          ...formInputValue,
          [e.target.name]: e.target.files[0],
        });
        const file = e.target.files[0];
        if (file) {
          const objectURL = URL.createObjectURL(file);
          setSelectedImage(objectURL);
        }
      }
    }
  };

  const validateForm = (data) => {

    const errors = {};

    if (!data.contactPhoneNumber) {
    } else {
      var expr = /^(0|91)?[6-9][0-9]{9}$/;
      if (!expr.test(data.contactPhoneNumber.replace("+", ''))) {
        errors.contactPhoneNumber = "Please enter valid phone number";
      }
    }

    if (!data.cityId && formInputValue?.userEventType === "OFFLINE") {
      errors.cityId = "City is required";
    }

    if (!data.categoryId) {
      errors.categoryId = "Category is required";
    }
    if (!data.businessTitle) {
      errors.businessTitle = "Event Title is required";
    }

    if (!data?.businessStreetAddress && formInputValue?.userEventType === "OFFLINE") {
      errors.businessStreetAddress = "House / Flat / Apartment number  is required";
    } else if (data?.businessStreetAddress?.length > 150 && formInputValue?.userEventType === "OFFLINE") {
      errors.businessStreetAddress = "House / Flat / Apartment number can Not be Greater than 150 char";
    }

    if (!googleAddress.businessAddress && formInputValue?.userEventType === "OFFLINE") {
      errors.businessAddress = "Event Address  is required";
    }

    if (!data.eventCharges) {
      errors.eventCharges = "Event Charges is required";
    }
    if (!data.userEventType) {
      errors.userEventType = "Event Type is required";
    }

    if (formInputValue?.userEventType === "ONLINE") {
      if (!data?.eventLink) {
      } else if (!isValidUrl(data?.eventLink)) {
        errors.eventLink = "Please enter valid  meeting link";
      } else if (data?.eventLink?.length > 150) {
        errors.eventLink = "EventLink url can not be greater than 150 char";
      }
    }

    if (!data?.facebookLink) {
    } else if (!isValidUrl(data?.facebookLink)) {
      errors.facebookLink = "Please enter valid  url";
    } else if (data?.facebookLink?.length > 150) {
      errors.facebookLink = "Url can not be greater than 150 char";
    }

    if (!data?.description) {
    } else if (data?.description?.length > 2000) {
      errors.description = "Description cannot exceed 2000 characters";
    }
    if (!data?.disclaimerText) {
    } else if (data?.disclaimerText?.length > 2000) {
      errors.disclaimerText = "Disclaimer Text cannot exceed 2000 characters";
    }

    if (!data?.instagramLink) {
    } else if (!isValidUrl(data?.instagramLink)) {
      errors.instagramLink = "Please enter valid  url";
    } else if (data?.instagramLink?.length > 150) {
      errors.instagramLink = "Url can not be greater than 150 char";
    }
    if (!data?.websiteLink) {
    } else if (!isValidUrl(data?.websiteLink)) {
      errors.websiteLink = "Please enter valid  url";
    } else if (data?.websiteLink?.length > 150) {
      errors.websiteLink = "Url can not be greater than 150 char";
    }

    if (!data?.hostName) {
    } else if (data?.hostName?.length > 150) {
      errors.hostName = "Host Name can not be greater than 150 char";
    }
    if (!data.email) {
    } else if (!isValidEmail(data?.email)) {
      errors.email = "Please enter valid email address";
    }
    if (!data.productImage) {
    } else if (!isImageType(data?.productImage?.name)) {
      errors.productImage = imagetypeError;
    }

    const startDateObject = new Date(data.startDate);
    const endDateObject = new Date(data.endDate);
    const currentDate = new Date();
    const maxDate = new Date(getMaxDate());

    if (!data.endDate) {
      errors.endDate = "End date is required";
    } else if (startDateObject > endDateObject) {
      errors.endDate = "End date can not less then start date";
    }

    if (!data.startDate) {
      errors.startDate = "Start date is required";
    } else if (currentDate > startDateObject) {
      errors.startDate = "Start date cannot be less than current date";
    } else if (startDateObject > maxDate) {
      errors.startDate = "Start date cannot be greater than 30 days from today!";
    }
    return errors;
  };

  const userRegister = async (img) => {
    setLoading(true);
    const headers = {
      accept: "*/*",
      "Content-Type": "application/json",
    };

    axios
      .post(
        apiBaseUrl + apiUrl.webRegisterUserEvent,
        {
          imageLink: img,
          imageUrl: img,
          disclaimerText: formInputValue?.disclaimerText,
          ticketCount: formInputValue?.availableQuantity,
          enablePayment: formInputValue?.enablePayment,
          emailAddress: formInputValue?.emailAddress,
          contactPhoneNumber: formInputValue?.contactPhoneNumber,
          displayUserNumber: formInputValue?.displayUserNumber,
          contactEmail: formInputValue?.email,
          contentSource: formInputValue?.social_media === "Other" ? formInputValue?.social_media_text || "Other" : formInputValue?.social_media,
          hostName: formInputValue?.hostName,
          userEventType: formInputValue?.userEventType,
          eventLink: formInputValue?.userEventType === "ONLINE" ? formInputValue?.eventLink : '',
          eventEndDate: dateSendFormate2(formInputValue?.endDate),
          eventStartDate: dateSendFormate2(formInputValue?.startDate),
          eventCharges: formInputValue?.eventCharges,
          instagramLink: formInputValue?.instagramLink,
          websiteLink: formInputValue?.websiteLink,
          facebookLink: formInputValue?.facebookLink,
          eventDescription: formInputValue?.description,
          categoryId: formInputValue?.categoryId,
          cityId: formInputValue?.userEventType === "OFFLINE" ? formInputValue?.cityId : '',
          countryCode: 91,
          userId: 0,
          latitude: formInputValue?.userEventType === "OFFLINE" ? googleAddress?.latitude : 0,
          longitude: formInputValue?.userEventType === "OFFLINE" ? googleAddress.longitude : 0,
          registeredPhoneNumber: formValue?.phoneNumber?.slice(-10),
          installationKey: formInputValue?.installationKey,
          eventStreetAddress: formInputValue?.userEventType === "OFFLINE" ? formInputValue?.businessStreetAddress : '',
          eventAddress: formInputValue?.userEventType === "OFFLINE" ? googleAddress?.businessAddress : '',
          eventTitle: formInputValue?.businessTitle,
        },
        {
          headers: headers,
        }
      )
      .then((response) => {
        if (response?.data?.responseType === "SUCCESS") {
          stepChange(3);

        } else {
          toast.error(
            "Something went wrong please try again after sometime "
          );
        }
      })
      .catch((error) => {
        toast.error("Something went wrong please try again after sometime ");
      })
      .finally(() => {
        setLoading(false);
      });

  };

  const headers2 = {
    Accept: "*/*",
    "Content-Type": "application/json",
  };

  const fileUploadRequest = () => {
    axios
      .post(
        apiBaseUrl + apiUrl.getUploadFileUrl,
        {
          cityId: formInputValue?.userEventType === "OFFLINE" ? formInputValue?.cityId : '',
          latitude: formInputValue?.userEventType === "OFFLINE" ? googleAddress?.latitude : '',
          longitude: formInputValue?.userEventType === "OFFLINE" ? googleAddress.longitude : '',
          registeredPhoneNumber: formValue?.phoneNumber?.slice(-10),
          installationKey: formInputValue?.installationKey,
          userId: 0,
          countryCode: 91,
          cardType: "EVENT",
          fileName: formInputValue?.productImage?.name,
          expirationDate: dateSendFormate2(formInputValue?.endDate),
        }, {
        headers: headers2,
      }
      )
      .then((response) => {
        if (response?.status === 200) {
          const contentType = response?.data?.mimeType
          const uploadUrl = response?.data?.url;
          const imageUrl = response?.data?.fileAccessUrl;

          let data = formInputValue?.productImage;
          let config = {
            method: 'put',
            maxBodyLength: Infinity,
            url: uploadUrl,
            headers: {
              'Content-Type': contentType
            },
            data: data
          };
          axios.request(config)
            .then((response) => {
              if (response.status === 200) {
                userRegister(imageUrl)

              } else {
                setLoading(false);
                toast.error("Something went wrong please try again after sometime 2");
              }
            })
            .catch((error) => {
              setLoading(false);
              toast.error("Something went wrong please try again after sometime 3");
            });
        }
        else {
          setLoading(false);
          toast.error(
            "Something went wrong please try again after sometime 4"
          );
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Something went wrong please try again after sometime 5");
      })
  }

  const submit = async () => {
    const validationErrors = await validateForm(formInputValue);
    if (Object.keys(validationErrors).length === 0) {
      setLoading(true)
      if (formInputValue?.productImage?.name) {
        fileUploadRequest()
      } else {
        userRegister('')
      }
    } else {
      for (const [key, value] of Object.entries(validationErrors)) {
        toast.error(value);
      }
    }
  }

  function getTagsByCategoryId(categoryId) {
    const selectedCategory = category?.filter(
      (category) => category?.categoryId == categoryId
    );

    if (selectedCategory[0]) {
      return selectedCategory[0]?.tags;
    }

    return [];
  }

  useEffect(() => {
    formInputValue?.categoryId &&
      setTagsList(getTagsByCategoryId(formInputValue?.categoryId));
  }, [formInputValue?.categoryId]);

  useEffect(() => {
    const initAutocomplete = () => {
      const input = document.getElementById('autocomplete');
      const autocomplete = new window.google.maps.places.Autocomplete(input);

      window.google.maps.event.addListener(autocomplete, 'place_changed', () => {
        const place = autocomplete.getPlace();
        setGoogleAddress({
          ...googleAddress,
          businessAddress: place?.formatted_address,
          latitude: place?.geometry?.location?.lat(),
          longitude: place?.geometry?.location?.lng(),
        });
      });
    };

    initAutocomplete();
  }, []);

  const [suggestedCities, setSuggestedCities] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [selectedCity, setSelectedCity] = useState(null);
  const handleInputChange = (e) => {
    setFormInputValue({
      ...formInputValue,
      cityName: '',
      city: '',
    });
    const value = e.target.value;
    setInputValue(value);

    const filteredCities = cityName?.cityNameList.filter(city =>
      city.cityName.toLowerCase().includes(trimString(value).toLowerCase())
    );

    setSuggestedCities(filteredCities);
  };

  const cityLoad = (value) => {
    const filteredCities = cityName?.cityNameList.filter(city =>
      city.cityName.toLowerCase().includes(trimString(value).toLowerCase())
    );
    setSuggestedCities(filteredCities);
  };

  const handleCityClick = (city) => {
    setSelectedCity(city);
    setInputValue(city.cityName);
    setFormInputValue({
      ...formInputValue,
      cityName: city.cityName,
      cityId: city.cityId,
    });
    setSuggestedCities([]);
  };

  return (
    <>
      <div className={`${loading && "disabled"}`}>
        <form className="business-stepper-form row g-3">


          <div className="col-12">
            <div className="form-control flag-input">
              <img src="/images/images/ind-icon.png" alt="India Flag" />
              <input type="text" id="phone" placeholder="Enter Host Contact Number"
                autoComplete="off"
                name="contactPhoneNumber"
                value={formInputValue?.contactPhoneNumber || ""}
                onChange={valueChange} />
            </div>
          </div>
          <div className="col-12">
            <input type="text" className="form-control" id="business_name"
              name="businessTitle"
              value={formInputValue?.businessTitle}
              onChange={valueChange}
              placeholder="Event Title" />
          </div>

          <div className="col-12">
            <input type="text" className="form-control" id="host_name"
              name="hostName"
              value={formInputValue?.hostName}
              onChange={valueChange}
              placeholder="Host Name" />
          </div>
          <div className="col-12 col-md-12">
            <select
              aria-label="Default select example"
              className="form-select form-control"
              name="social_media"
              value={formInputValue?.social_media}
              onChange={valueChange}
            >
              <option value="" disabled selected>
                Select a Source
              </option>
              {socialName?.social_media?.map((res, index) => (
                <option key={index} value={res?.value}>
                  {res?.name}
                </option>
              ))}
            </select>
          </div>
          {formInputValue?.social_media === "Other" &&
            <div className="col-12 col-md-12">
              <input
                type="text"
                className="form-control"
                value={formInputValue?.social_media_text || ""}
                name="social_media_text"
                onChange={valueChange}
                placeholder="Source"
              />
            </div>
          }
          <div className="col-12 col-md-6">
            <label><strong>Start date</strong></label>
            <input
              type="datetime-local"
              className="form-control"
              id="start_date"
              value={formInputValue?.startDate || ""}
              name="startDate"
              onChange={valueChange}
              placeholder="Start date"
              required="" />
          </div>
          <div className="col-12 col-md-6">
            <label><strong>End date</strong></label>
            <input
              type="datetime-local"
              className="form-control"
              id="end_date"
              value={formInputValue?.endDate || ""}
              name="endDate"
              onChange={valueChange}

              placeholder="End date"
              required=""
            />
          </div>
          <div className="col-12 col-md-6">
            <div className="input-group">
              <span className="input-group-text bg-transparent primary">₹</span>
              <input
                className="form-control"
                id="price"
                type="text"
                name="eventCharges"
                value={formInputValue?.eventCharges}
                onChange={valueChange}
                placeholder="Event Charges" />
            </div>
          </div>
          <div className="col-12 col-md-6 d-flex align-items-center justify-content-between">
            <label for="onlineOfflineSwitch">My Event Is  </label>
            <div className="form-check form-switch">
              <label for="onlineOfflineButton" className="text-offline">Offline</label>
              <input className="form-check-input" type="checkbox" id="onlineOfflineButton" name="userEventType"
                checked={formInputValue.userEventType === "ONLINE"}
                onChange={handleSwitchToggle}
              />
              <label for="onlineOfflineButton" className="text-online">Online</label>
            </div>
          </div>

          <div className="col-12 col-md-12 d-flex align-items-center justify-content-between">
            <label for="onlineOfflineSwitch">Enable payment via listy city <a href="/faq#paymentsCharge" target="_blank"><img style={{ width: "25px", cursor: "pointer" }} src="/images/images/help-icon.png" alt="Help Icon" /></a></label>
            <div className="form-check form-switch">
              <label for="onlinePaymnetButton" className="text-offline">No</label>
              <input className="form-check-input" type="checkbox" id="onlinePaymnetButton" name="enablePayment"
                checked={formInputValue.enablePayment}
                onChange={(e) => {
                  setFormInputValue({
                    ...formInputValue,
                    enablePayment: e.target.checked,
                  });
                }}
              />
              <label for="onlinePaymnetButton" className="text-online">Yes</label>
            </div>
          </div>
          {formInputValue.enablePayment &&
            <>
              <div className="col-12 col-md-12">
                <div className="input-group">
                  <input
                    className="form-control"
                    id="price"
                    type="text"
                    name="availableQuantity"
                    value={formInputValue?.availableQuantity}
                    onChange={valueChange}
                    placeholder="Total number of tickets" />
                </div>
              </div>
            </>
          }

          {formInputValue?.userEventType === "OFFLINE" &&
            <>
              <div className="col-12">
                <div className="intro-search-field utf-chosen-cat-single">

                  <input
                    className="form-select form-control"
                    type="text"
                    value={inputValue}
                    onChange={handleInputChange}
                    onFocus={() => cityLoad(inputValue)}
                    onBlur={() => {
                      setTimeout(() => {
                        setSuggestedCities([])
                      }, 500)
                    }
                    }
                    placeholder="Type to search city"
                    style={{ margin: 0 }}
                  />
                  <div style={{
                    width: "100%",
                    position: "relative"
                  }}>
                    {suggestedCities?.length > 0 && <ul style={{
                      position: "absolute",
                      background: "#fff",
                      border: "solid 1px #ddd",
                      maxHeight: "150px",
                      overflow: "auto",
                      listStyle: "none",
                      padding: "10px",
                      zIndex: 99,
                      width: "100%"
                    }}>
                      {suggestedCities?.map(city => (
                        <li style={{ padding: "0px", fontSize: "16px" }} key={city.cityId} onClick={() => handleCityClick(city)}>
                          {city.cityName}
                        </li>
                      ))}
                    </ul>}
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <input
                  className="form-control"
                  type="text"
                  name="businessStreetAddress"
                  value={formInputValue?.businessStreetAddress}
                  onChange={valueChange}
                  placeholder="House / Flat / Apartment number"
                />
              </div>

            </>}
          <div className="col-12 col-md-6" style={{ display: `${formInputValue?.userEventType === "OFFLINE" ? 'block' : 'none'}` }}>
            <input
              className="form-control"
              autoComplete="off"

              type="text" id="autocomplete" placeholder="Type to search location" />

          </div>
          {formInputValue?.userEventType === "ONLINE" &&
            <>
              <div className="col-12 col-md-12">
                <input
                  className="form-control"
                  type="url"
                  name="eventLink"
                  value={formInputValue?.eventLink}
                  onChange={valueChange}
                  placeholder="Event Link (Can be updated later via app)"
                  required
                />
              </div>
            </>}

          <div className="col-12 col-md-12">
            <select
              aria-label="Default select example"
              className="form-select form-control"
              name="categoryId"
              value={formInputValue?.categoryId}
              onChange={valueChange}
            >
              <option value="" disabled selected>
                Select a Category
              </option>
              {category?.map((res, index) => (
                <option key={index} value={res?.categoryId}>
                  {res?.categoryText}
                </option>
              ))}
            </select>
          </div>

          <div className="col-12 col-md-12 d-flex align-items-center justify-content-between">
            <label for="onlineOfflineSwitch2">Display My Phone Number  On Listing  <a href="/faq#displayPhone" target="_blank"><img style={{ width: "25px", cursor: "pointer" }} src="/images/images/help-icon.png" alt="Help Icon" /></a></label>
            <div className="form-check form-switch">
              <label for="onlinePaymnetButton2" className="text-offline">No</label>
              <input className="form-check-input" type="checkbox" id="onlinePaymnetButton2" name="displayUserNumber"
                checked={formInputValue.displayUserNumber}
                onChange={(e) => {
                  setFormInputValue({
                    ...formInputValue,
                    displayUserNumber: e.target.checked,
                  });
                }}
              />
              <label for="onlinePaymnetButton2" className="text-online">Yes</label>
            </div>
          </div>

          <div className="col-12 position-relative">
            <div className="custom-file">
              <input type="file" id="file_upload" className="custom_file_uploader" accept="image/*"
                name="productImage"
                onChange={fileChange} />
              <div className="custom-file-content">
                <img src="/images/images/upload-icon.svg" alt="Upload Icon" />
                <strong>Upload File</strong>
                <span className="ms-auto">(JPEG/PNG Under 2mb)</span>
              </div>
            </div>

          </div>
          {selectedImage && (
            <div className="col-md-6 col-12" >
              <div style={{ position: 'relative', display: 'inline-block', marginTop: '10px' }}>
                <img
                  src={selectedImage}
                  alt="Preview"
                  style={{ maxWidth: '200px', maxHeight: '200px', borderRadius: '8px' }}
                />
                <span
                  onClick={() => {
                    setFormInputValue({
                      ...formInputValue,
                      productImage: null,
                    });
                    setSelectedImage(null)
                  }}
                  style={{
                    position: 'absolute',
                    top: '5px',
                    right: '5px',
                    backgroundColor: 'red',
                    color: 'white',
                    border: 'none',
                    borderRadius: '50%',
                    cursor: 'pointer',
                    width: '24px',
                    height: '24px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  &times;
                </span>
              </div>
            </div>
          )}
          <div class="accordion accordion-flush " id="accordionFlushExample">
            <div class="accordion-item border">
              <h2 class="accordion-header" id="flush-headingOne">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                  Add Additional Details
                </button>
              </h2>
              <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">

                  <div className="col-12">
                    <input type="text" className="form-control" id="business_name"
                      name="email"
                      value={formInputValue?.email}
                      onChange={valueChange}
                      placeholder="Email Address" />
                  </div>
                  <div className="col-12 col-md-12  mt-3">
                    <input
                      className="form-control"
                      type="url"
                      name="facebookLink"
                      value={formInputValue?.facebookLink}
                      onChange={valueChange}
                      placeholder="Facebook Url"
                    />
                  </div>
                  <div className="col-12 col-md-12  mt-3">
                    <input
                      className="form-control"
                      type="url"
                      name="instagramLink"
                      value={formInputValue?.instagramLink}
                      onChange={valueChange}
                      placeholder="Instagram Url"
                    />
                  </div>
                  <div className="col-12 col-md-12  mt-3">
                    <input
                      className="form-control"
                      type="url"
                      name="websiteLink"
                      value={formInputValue?.websiteLink}
                      onChange={valueChange}
                      placeholder="Website/ Registration / Payment link"
                    />
                  </div>
                  <div className="col-12 col-md-12  mt-3">
                    <input
                      rows="3"
                      style={{ minHeight: "100px" }}
                      className="form-control"
                      type="text"
                      name="description"
                      value={formInputValue?.description}
                      onChange={valueChange}
                      placeholder="Enter description "
                    />
                  </div>
                  {formInputValue.enablePayment &&
                    <>
                      <div className="col-12 col-md-12  mt-3">
                        <input
                          rows="3"
                          style={{ minHeight: "100px" }}
                          className="form-control"
                          type="text"
                          name="disclaimerText"
                          value={formInputValue?.disclaimerText}
                          onChange={valueChange}
                          placeholder="Payment Disclaimer"
                        />
                      </div>
                    </>
                  }
                </div>
              </div>
            </div >
          </div >

          <div className="col-12 mt-5">
            <a href="javaScript:void(0)" className="btn btn-purple w-100" onClick={() => {
              submit();
            }}>Continue</a>
          </div>
        </form>
      </div>
    </>
  );
};

export default RegisterPageStep2;
