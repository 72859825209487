export const INCREMENT = 'INCREMENT';
export const DECREMENT = 'DECREMENT';
export const ADDEVENTDATA = 'ADDEVENTDATA';
export const REMOVEEVENTDATA = 'REMOVEEVENTDATA';

export const increment = () => ({
  type: INCREMENT,
});

export const decrement = () => ({
  type: DECREMENT,
});

export const addEventData = (data) => ({
  type: ADDEVENTDATA,
  payload:data || {}
});