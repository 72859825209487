import axios from "axios";
import { useRef, useState } from "react";
import {
  apiBaseUrl,
  apiBaseUrl2,
  apiBaseUrl3,
  apiUrl,
  loadRazorpayScript,
} from "../important/commanFunction";
import { toast } from "react-toastify";

const RegisterPageStep2 = (props) => {

  const { stepChange, formValue, id, details, buttonTitle = "Verify OTP" } = props;
  const [loading, setLoading] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState("");
  const [formInputValue, setFormInputValue] = useState({
    installationKey: formValue?.webInstallationKey,
    userFullName: formValue?.userFullName,
    userEmailAddress: formValue?.userEmailAddress,
    phoneNumber: formValue?.phoneNumber,
    countryCode: 91,
    accept: true
  });

  const inputRefs = useRef([]);

  const varifyOtp = async () => {

    if (!formInputValue?.otp) {
      toast.error("Otp is required");
    } else if (formInputValue?.otp.length != 4) {
      toast.error("Please enter Valid Otp");
    }

    if (formInputValue?.otp && formInputValue?.otp.length === 4) {
      setLoading(true)
      const headers = {
        accept: "*/*",
        "Content-Type": "application/json",
      };

      if (details?.workFlowVO?.workflowType === "PAYMENT") {
        const isScriptLoaded = await loadRazorpayScript();

        if (!isScriptLoaded) {
          alert("Failed to load Razorpay. Please check your internet connection.");
          return;
        }
        axios
          .post(
            apiBaseUrl3 + apiUrl.createEventEnrollmentOrder,
            {
              countryCode: 91,
              otp: formInputValue?.otp,
              phoneNumber: formInputValue?.phoneNumber?.slice(-10),
              quantity: 1,
              userEmailAddress: formInputValue?.userEmailAddress,
              userFullName: formInputValue?.userFullName,
              enrollmentSourceId: id
            },
            {
              headers: headers,
            }
          )
          .then((response) => {
            if (response?.data?.verified == true) {

              const paymentOrderVO = response?.data?.paymentOrderVO || {}
              if (paymentOrderVO?.orderId && paymentOrderVO?.amount > 0) {

                const paymentOptions = {
                  key: paymentOrderVO?.key,
                  amount: paymentOrderVO?.amount,
                  currency: "INR",
                  name: "Listy City",
                  description: paymentOrderVO?.description,
                  image: paymentOrderVO?.imageUrl,
                  order_id: paymentOrderVO?.orderId,
                  handler: (res) => {

                    axios
                      .post(
                        apiBaseUrl3 + apiUrl.processEventEnrollmentOrder,
                        {
                          invoiceId: paymentOrderVO?.invoiceNumber,
                          signature: res?.razorpay_signature,
                          orderId: paymentOrderVO?.orderId,
                          paymentId: res.razorpay_payment_id,
                          countryCode: 91,
                          emailAddress: formInputValue?.userEmailAddress,
                          userName: formInputValue?.userFullName,
                          phoneNumber: formInputValue?.phoneNumber?.slice(-10),
                          registeredPhoneNumber: formInputValue?.phoneNumber?.slice(-10),
                          installationKey: response?.data?.webInstallationKey,
                        },
                        {
                          headers: headers,
                        }
                      )
                      .then((last) => {
                        if (last?.data?.verified == true) {
                          console.log(last.data, 'ooo')
                          if (
                            response?.data?.businessAlreadyExists == true
                          ) {
                            stepChange(4);
                          } else {
                            stepChange(3);
                          }
                        }
                      }).catch((error) => {
                        stepChange(3);
                        // setPaymentStatus('failed')
                        console.error("Error:", error);
                        // toast.error("Something went wrong please try again after sometime ");
                      }).finally(() => setLoading(false));

                  },
                  prefill: {
                    name: formInputValue?.userFullName,
                    email: formInputValue?.userEmailAddress,
                    contact: formInputValue?.phoneNumber?.slice(-10),
                  },
                  theme: {
                    color: "#3399cc",
                  },
                  modal: {
                    ondismiss: () => {
                      setPaymentStatus('closed')
                    }
                  },
                };

                try {
                  const razorpayInstance = new window.Razorpay(paymentOptions);
                  razorpayInstance.on('payment.failed', (response) => {
                    setPaymentStatus('failed')
                  });
                  razorpayInstance.open();

                } catch (error) {
                  setPaymentStatus('failed')
                }

              } else {
                if (
                  response?.data?.businessAlreadyExists == true
                ) {
                  stepChange(4);
                } else {
                  stepChange(3);
                }
              }

            } else {
              setLoading(false)
              toast.error("Please enter Valid Otp");
            }
          })
          .catch((error) => {
            console.error("Error:", error);
            setLoading(false)
            toast.error("Something went wrong please try again after sometime ");
          }).finally(() => {

          });

      } else {
        axios
          .post(
            apiBaseUrl2 + apiUrl.verifyEventEnrollmentOTP,
            {
              countryCode: 91,
              otp: formInputValue?.otp,
              phoneNumber: formInputValue?.phoneNumber?.slice(-10),
              userEmailAddress: formInputValue?.userEmailAddress,
              userFullName: formInputValue?.userFullName,
              enrollmentSourceId: id
            },
            {
              headers: headers,
            }
          )
          .then((response) => {
            if (response?.data?.verified == true) {
              console.log(response.data, 'ooo')
              if (
                response?.data?.businessAlreadyExists == true
              ) {
                stepChange(4);
              } else {
                stepChange(3);
              }
            } else {
              toast.error("Please enter Valid Otp");
            }
          })
          .catch((error) => {
            console.error("Error:", error);
            toast.error("Something went wrong please try again after sometime ");
          }).finally(() => setLoading(false));
      }
    }
  };

  const valueChangeOtp = (e, index) => {
    const value = e.target.value;
    const numericValue = value.replace(/[^0-9]/g, "");
    e.target.value = numericValue;

    if (numericValue.length === 1 && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1].focus();
    }

    const otp = inputRefs.current.map(ref => ref.value).join("");

    setFormInputValue({
      ...formInputValue,
      otp: otp,
    });
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && index > 0 && !e.target.value) {
      inputRefs.current[index - 1].focus();
    }
  };
  const validateForm = (data) => {
    const errors = {};
    if (!data.userFullName) {
      errors.userFullName = "Name is required";
    } else if (data?.userFullName.length > 150) {
      errors.userFullName = "Name canNot be Greater than 150 char";
    }

    if (!data.accept) {
      errors.accept = "Accept the Terms of Service";
    }

    if (!data.phoneNumber) {
      errors.phoneNumber = "Phone Number is required";
    } else {
      var expr = /^(0|91)?[6-9][0-9]{9}$/;
      if (!expr.test(data.phoneNumber.replace("+", ''))) {
        errors.phoneNumber = "Please enter valid phone number";
      }
    }
    return errors;
  };

  const otpSend = () => {
    const validationErrors = validateForm(formInputValue);
    if (Object.keys(validationErrors).length === 0) {
      const headers = {
        accept: "*/*",
        "Content-Type": "application/json",
      };
      setLoading(true)

      axios
        .post(
          apiBaseUrl + apiUrl.sendOTPToWebUser,
          {
            phoneNumber: formInputValue?.phoneNumber?.slice(-10),
            countryCode: 91
          },
          {
            headers: headers,
          }
        )
        .then((response) => {
          if (response?.data?.responseType === "SUCCESS") {
            stepChange(2);
            toast.success("OTP sent successfully");
          } else {
            toast.error(
              "Something went wrong please try again after sometime "
            );
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          toast.error("Something went wrong please try again after sometime ");
        }).finally(() => {
          setLoading(false)
        });
    } else {
      for (const [key, value] of Object.entries(validationErrors)) {
        toast.error(value);
      }
    }
  };

  return (
    <>
      {!paymentStatus &&
        <div className={`row ${loading && "disabled"}`}>
          <form className="business-stepper-form row g-3">
            {Array(4).fill(0).map((_, index) => (
              <div className="col" key={index}>
                <input
                  type="text"
                  className="form-control text-center"
                  maxLength="1"
                  placeholder="-"
                  ref={(el) => inputRefs.current[index] = el}
                  onChange={(e) => valueChangeOtp(e, index)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                />
              </div>
            ))}

            <div className="col-12 text-end mt-3 mb-5">
              <a href="javaScript:void(0)" onClick={otpSend}>Resend OTP</a>
            </div>
            <div className="col-12">
              <a href="javaScript:void(0)" onClick={varifyOtp} className="btn btn-purple w-100">{buttonTitle}</a>
            </div>
          </form>
        </div>
      }
      {paymentStatus === "failed" &&
        <div class="payment-failed">
          <div class="">
            <h2>Payment Failed</h2>
            <p>Unfortunately, your payment could not be processed. Please try again.</p>
            <button class="close-btn" onClick={() => stepChange(1)}>Retry payment</button>
          </div>
        </div>
      }
      {paymentStatus === "closed" &&
        <div class="payment-failed">
          <div class="">
            <h2>Payment Cancelled</h2>
            <p>You closed the payment window. Please try again if you wish to proceed with the payment.</p>
            <button class="close-btn" onClick={() => stepChange(1)}>Retry payment</button>
          </div>
        </div>
      }
    </>
  );
};

export default RegisterPageStep2;
