import React, { useEffect, useState } from "react";
import "../css/faqPage.css";
import { useLocation } from "react-router-dom";

const FAQsPage = () => {
    const faqs = [
        {
            question: "Allow listy city to manage payments?",
            answer:
                [
                    "When you publish and manage your events using listy city. We charge the lowest possible platform fee. Listy City takes flat 5% platform fee for the tickets sold. This does not include the GST or other chargers levied by the payment service provider.",
                    "In the case of free tickets, no commission is charged.",
                    "No commission is charged if you accept payment for your event ticket at the event venue or by some other payment mode that does not involve listy city. In other words, irrespective of your ticket price, you won't be charged for anything."
                ],
            id: "paymentsCharge"
        },
        {
            question: "Online versus Offline Events?",
            answer: [
                "Online events include all those events and services which are rendered online. ",
                "Listy City is not responsible for creating and hosting of online events. The meeting link will be created and updated by the meeting creator – and must be done 48 hours before the start of the event so that this link can be shared with the participants.",
                "For physical events listy city is not responsible for event arrangements. Once created event venue can only be changed by sending a request to contact@listy.city"
            ],
            id: "eventType"
        },
        {
            question: "Privacy & Display Of  Phone Number On Listing?",
            answer: [
                "To allow attendees to contact you directly you can choose to display your number on event listing. ",
                "By default your registered number is not displayed on the event listing. However you can easily enable this via the app.",
                "Should you choose to display your number, listy city bears no responsibility in any calls that you may receive."
            ],
            id: "displayPhone"
        },
    ];

    const [activeIndex, setActiveIndex] = useState(null);
    const toggleFAQ = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const location = useLocation();

    useEffect(() => {
        const hash = location.hash.replace("#", "");
        if (hash) {
            const index = faqs.findIndex(faq => faq.id === hash);
            if (index !== -1) {
                setActiveIndex(index);
            }
        }
    }, [location]);

    return (
        <div className="faqs-page">
            <div style={{ textAlign: 'center' }}>
                <h1 className="page-title">FAQ</h1>
                <p className="page-subtitle">
                    Find answers to the most common questions about our platform.
                </p>
            </div>

            <div className="faqs-container">
                {faqs?.map((faq, index) => (
                    <div
                        className={`faq-item ${activeIndex === index ? "active" : ""}`}
                        key={faq.id}
                        id={faq.id}
                        onClick={() => toggleFAQ(index)}
                    >
                        <div className="faq-question" id={`heading${index}`}>
                            <h6 style={{ margin: 0 }}>{faq.question}</h6>
                            <span className="faq-icon">
                                {activeIndex === index ? "-" : "+"}
                            </span>
                        </div>
                        {activeIndex === index && (
                            <div className="faq-answer">
                                <ul>
                                    {faq.answer.map((ans, i) => (
                                        <li key={i}>{ans}</li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FAQsPage;
