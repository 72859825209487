import { Link } from "react-router-dom";
import { pageRoutes } from "../important/commanFunction";

const Header1 = () => {
  return (
    <>
      <header className="header home other px-2 px-md-5">
        <nav style={{padding:'0px'}} className="navbar navbar-expand-md navbar-dark bg-transparent" aria-label="Fourth navbar example">
          <div className="container-fluid">
            <Link className="navbar-brand" to={pageRoutes.home} style={{padding:'0px'}}>
              <img src="/images/images/logo.png" alt="Brand Logo" style={{height:'85px'}}/>
            </Link>
            <ul className="navbar-nav nowrap align-items-center gap-4 ms-auto mb-2 mb-md-0">
              <li className="nav-item">
                <Link className="nav-link btn btn-purple" to={pageRoutes.addEvent}>
                  Launch Event
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link btn btn-purple" to={pageRoutes.registerBusiness}>
                  List Business
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    </>
  )
}

export default Header1;