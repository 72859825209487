import { useEffect, useState } from "react";
import EnrollNowPageStep1 from "./EnrollNowPageStep1";
import EnrollNowPageStep2 from "./EnrollNowPageStep2";
import EnrollNowPageStep3 from "./EnrollNowPageStep3";
import { toast } from "react-toastify";
import axios from "axios";
import { apiBaseUrl, apiUrl } from "../important/commanFunction";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import RegisterPageSoldOut from "./EnrollNowPageSoldOut";
import { useDispatch, useSelector } from "react-redux";
import { addEventData } from "../actions/siteActions";

const RegisterBusinessPage = () => {
  const [registerStep, setRegisterStep] = useState(1);
  const [formValue, setFormValue] = useState({});
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const details = useSelector((state) => state.eventData) || {};

  const { type, slug } = useParams();

  const getDetails = () => {
    const headers = {
      Accept: "*/*",
      "Content-Type": "application/json",
    };

    axios
      .post(
        apiBaseUrl + apiUrl.getDetailsByType,
        { slug, type },
        {
          headers: headers,
        }
      )
      .then((response) => {
        if (response?.data?.responseType === "SUCCESS") {
          dispatch(addEventData(response?.data?.cardDetailsVO || {}))
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (type && slug) {
      if (!details?.cardDetailId) {
        getDetails();
      } else {
        setLoading(false)
      }
    }
  }, [slug, type]);


  const inputChange = (name, value) => {
    setFormValue({
      ...formValue,
      [name]: value,
    });
  };

  useEffect(() => {
    if (registerStep === 3) {
      toast.success("Reminder added successfully");
    }
  }, [registerStep])


  return (
    <>
      {loading && (
        <>

          <div className="loading_main_div">
            <div className="loader_new">
            </div>
          </div>
        </>
      )}
      {!loading && !details?.cardDetailId && (
        <div className="data_not_found">
          <p>Record not found</p>
        </div>
      )}
      {!loading && details?.cardDetailId && (
        <section className="business-onboarding" style={{ marginBottom: "20px" }}>
          <div className="container">
            {details?.workFlowVO?.workflowType === 'SOLD_OUT' ? (
              <div>
                <div className="row">
                  <div className="col-12 col-md-6 d-none d-md-block">
                    <img src="/images/images/grow-image.png" className="w-100 mb-5" alt="Business List Image" />
                  </div>
                  <div className="col-12 col-md-6 ">
                    <RegisterPageSoldOut />

                  </div>
                </div>
              </div>
            ) : (<> {(details?.workFlowVO?.workflowType != "PAYMENT" || !(details?.price > 0)) &&
              <>
                <div className="row">
                  <div className="col-12 col-md-6 d-none d-md-block">
                    <img src="/images/images/grow-image.png" className="w-100 mb-5" alt="Business List Image" />
                  </div>
                  <div className="col-12 col-md-6 ">
                    <h2 className="primary text-center mb-5">Sign up for a event in 3 easy, quick steps.</h2>
                    <ul className="stepper-form">
                      <li>
                        <div className="stepper-item">
                          <span>1</span> Details
                        </div>
                        <span className={`progress ${registerStep === 1 && 'bg-warning'}`}></span>
                      </li>
                      <li>
                        <div className="stepper-item">
                          <span>2</span> OTP
                        </div>
                        <span className={`progress ${registerStep === 2 && 'bg-warning'}`}></span>
                      </li>
                      <li>
                        <div className="stepper-item">
                          <span>3</span> All Set!
                        </div>
                        <span className={`progress ${registerStep > 2 && 'bg-warning'}`}></span>
                      </li>
                    </ul>
                    {registerStep === 1 && (
                      <EnrollNowPageStep1
                        formValue={formValue}
                        inputChange={inputChange}
                        stepChange={(val) => {
                          setRegisterStep(val);
                        }}
                      />
                    )}
                    {registerStep === 2 && (
                      <EnrollNowPageStep2
                        id={details?.cardDetailId}
                        details={details}
                        formValue={formValue}
                        inputChange={inputChange}
                        stepChange={(val) => {
                          setRegisterStep(val);
                        }}
                      />
                    )}
                    {(registerStep === 3 || registerStep === 4) && (
                      <EnrollNowPageStep3
                        registerStep={registerStep}
                      />
                    )}
                  </div>
                </div>
              </>
            }
            </>
            )}

            {details?.workFlowVO?.workflowType === "PAYMENT" && details?.price > 0 &&
              <>
                <div className="desktop-only">
                  <div className="row">

                    <div className="col-md-6 main-details-page-content" >
                      <div className="head_details">
                        <span>{details?.title}</span>
                        <a href={details?.images[0]} className="location mt-2" target="_blank"> <img src={details?.images[0]} alt="Event Cover Image" /></a>
                      </div>
                      {(details?.startDate || details?.startDate) &&
                        <div className="time_details">
                          {details?.startDate &&
                            <div className="date-container">
                              <div className="date-box">
                                <div className="month">{details?.startDate ? moment(details?.startDate).format("MMM") : "N/A"}</div>
                                <div className="day">{details?.startDate ? moment(details?.startDate).format("DD") : "N/A"}</div>
                              </div>
                              <div className="date-details">
                                <div className="day-date"> {details?.startDate ? moment(details?.startDate).format("ddd DD MMM") : "N/A"}</div>
                                <div className="time"> {details?.startDate
                                  ? `${moment(details?.startDate).format("h:mmA")}`
                                  : "N/A"}</div>
                              </div>
                            </div>
                          }
                          {details?.endDate && details?.startDate && <> To </>}
                          {
                            details?.endDate &&
                            <div className="date-container">
                              <div className="date-box">
                                <div className="month">{details?.endDate ? moment(details?.endDate).format("MMM") : "N/A"}</div>
                                <div className="day">{details?.endDate ? moment(details?.endDate).format("DD") : "N/A"}</div>
                              </div>
                              <div className="date-details">
                                <div className="day-date"> {details?.endDate ? moment(details?.endDate).format("ddd DD MMM") : "N/A"}</div>
                                <div className="time"> {details?.endDate
                                  ? `${moment(details?.endDate).format("h:mmA")}`
                                  : "N/A"}</div>
                              </div>
                            </div>
                          }
                        </div>
                      }

                      <div className="description_details" style={{ border: 'none' }}>
                        {(details?.price || details?.price === 0) &&
                          <p className="list-group-item"> <span className="head_text">Price : </span>{details?.price ? `₹ ${details?.price}` : details?.price === 0 ? `Free` : ''}</p>
                        }
                        {details?.category && <>
                          <p >
                            <span className="head_text">Category : </span>
                            {details?.category}
                          </p>
                        </>
                        }

                        {details?.highlights[0] && <p> {details?.highlights[0]}</p>}

                        {details?.address && <>
                          <p ><span className="head_text">Event Location : </span> {details?.address}
                            {" "}
                            {(details?.latitude == 0 && details?.longitude == 0) ? (
                              <a href={`https://maps.google.com/?q=${details?.address}`} className="location" target="_blank"><img
                                src="/images/images/link-icon.svg" alt="Link Icon" />
                              </a>
                            ) : (
                              <a href={`https://maps.google.com/?q=${details?.latitude},${details?.longitude}`} className="location" target="_blank">
                                <img
                                  src="/images/images/link-icon.svg" alt="Link Icon" />

                              </a>
                            )}
                          </p>
                        </>}

                        {details?.eventLink &&
                          <div>
                            <h4>View Event</h4>
                            <p>
                              <span> {details?.eventLink} </span>
                              <a href={details?.eventLink} className="m-auto me-0" target="_blank">
                                <img
                                  src="/images/images/link-icon.svg" alt="Link Icon" />
                              </a>
                            </p>
                          </div>
                        }
                        {details?.description && <>
                          <h3>Description</h3>
                          <p>{details?.description}</p>
                        </>
                        }
                      </div>
                    </div>
                    <div className="col-12 col-md-6 ">
                      <h2 className="primary text-center mb-5">Sign up for a event in 3 easy, quick steps.</h2>
                      <ul className="stepper-form">
                        <li>
                          <div className="stepper-item">
                            <span>1</span> Details
                          </div>
                          <span className={`progress ${registerStep === 1 && 'bg-warning'}`}></span>
                        </li>
                        <li>
                          <div className="stepper-item">
                            <span>2</span> OTP
                          </div>
                          <span className={`progress ${registerStep === 2 && 'bg-warning'}`}></span>
                        </li>
                        <li>
                          <div className="stepper-item">
                            <span>3</span> All Set!
                          </div>
                          <span className={`progress ${registerStep > 2 && 'bg-warning'}`}></span>
                        </li>
                      </ul>
                      {registerStep === 1 && (
                        <EnrollNowPageStep1
                          buttonTitle="Send OTP And Pay"
                          formValue={formValue}
                          inputChange={inputChange}
                          stepChange={(val) => {
                            setRegisterStep(val);
                          }}
                        />
                      )}
                      {registerStep === 2 && (
                        <EnrollNowPageStep2
                          buttonTitle="Verify OTP & Complete payment"
                          id={details?.cardDetailId}
                          details={details}
                          formValue={formValue}
                          inputChange={inputChange}
                          stepChange={(val) => {
                            setRegisterStep(val);
                          }}
                        />
                      )}
                      {(registerStep === 3 || registerStep === 4) && (
                        <EnrollNowPageStep3
                          registerStep={registerStep}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="mobile-only">
                  <div className="row">
                    <div className="col-md-12">

                      <h2 className="primary text-center mb-5">Sign up for a event in 3 easy, quick steps.</h2>
                    </div>

                    <div className="col-12 col-md-6 ">
                      <ul className="stepper-form">
                        <li>
                          <div className="stepper-item">
                            <span>1</span> Details
                          </div>
                          <span className={`progress ${registerStep === 1 && 'bg-warning'}`}></span>
                        </li>
                        <li>
                          <div className="stepper-item">
                            <span>2</span> OTP
                          </div>
                          <span className={`progress ${registerStep === 2 && 'bg-warning'}`}></span>
                        </li>
                        <li>
                          <div className="stepper-item">
                            <span>3</span> All Set!
                          </div>
                          <span className={`progress ${registerStep > 2 && 'bg-warning'}`}></span>
                        </li>
                      </ul>
                      {registerStep === 1 && (
                        <EnrollNowPageStep1
                          buttonTitle="Send OTP And Pay"
                          formValue={formValue}
                          inputChange={inputChange}
                          stepChange={(val) => {
                            setRegisterStep(val);
                          }}
                        />
                      )}
                      {registerStep === 2 && (
                        <EnrollNowPageStep2
                          buttonTitle="Verify OTP & Complete payment"
                          id={details?.cardDetailId}
                          details={details}
                          formValue={formValue}
                          inputChange={inputChange}
                          stepChange={(val) => {
                            setRegisterStep(val);
                          }}
                        />
                      )}
                      {(registerStep === 3 || registerStep === 4) && (
                        <EnrollNowPageStep3
                          registerStep={registerStep}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </>
            }
          </div>
        </section>
      )}
    </>
  );
};

export default RegisterBusinessPage;
