import { Link } from "react-router-dom";
import {
  pageRoutes,
} from "../important/commanFunction";

const RegisterPageSoldOut = () => {
  return (
    <><form className="business-stepper-form row g-3">
      <div className="col-12 d-flex flex-column align-items-center gap-3">
        <img src="/images/images/qr-scanner.png" alt="QR Code" className="d-none d-md-block" />
        <h4 className="d-none d-md-block">Scan the QR Code to download the app.</h4>
        <h4 className="d-block d-md-none">Download the App & Start Exploring Today</h4>
        <p>
          You just missed it! This event is sold out :{"("} . Download the listy city app to discover more such events from in and around your city.
        </p>
        <p >
          Discover events on the <strong>Listy City</strong> app and create your own. It’s easy to connect with your customers on Listy. You can <span className="qr1"> personalize </span> your profile, get your own <span className="qr2"> webpage, </span> and create exciting <span className="qr3">events.</span>
        </p>
      </div>
      <div className="col-12">
        <Link className="nav-link btn btn-purple d-block" to={pageRoutes?.addEvent} >Launch your event</Link>
      </div>
    </form>
    </>
  );
};

export default RegisterPageSoldOut;
